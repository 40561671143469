import Component from 'flarum/common/Component';
import Dropdown from 'flarum/components/Dropdown';
import EditArticleDropdown from '../Dropdowns/EditArticleDropdown';

export default class ArticleControls extends Component {
  view() {
    const article = this.attrs.article;
    const hasControls = app.session.user && (app.session.user.canEdit() || article.canRename() || (article.posts() && article.posts()[0].canEdit()));

    return (
      <div className={"ButtonGroup Support-Settings-Holder" + (hasControls ? ' Contains-Multiple' : '')}>
        {Dropdown.component({
          icon: 'fas fa-share-square',
          label: app.translator.trans('v17development-flarum-support.forum.article.share_article.title'),
          buttonClassName: `Button ${this.attrs.disabled && this.attrs.disabled === true ? 'disabled' : ''}`,
          className: 'Support-Dropdown-Share',
          menuClassName: 'Dropdown-menu--right'
        }, [
          <b>{app.translator.trans('v17development-flarum-support.forum.article.share_article.perma_link')}</b>,
          <input 
            className="FormControl PostMeta-permalink"
            readOnly={true}
            value={window.location.origin + app.route('knowledgeBaseArticle', { id: article.id() })}
            onclick={ e => { $(e.target).focus().select() } } 
            style="opacity: 1" 
            />,
          // <div style={{ marginTop: '15px' }}>
          //   <b>Share this article on social media</b>
          //   <div>
          //     <a href=""></a>
          //   </div>
          // </div>
        ])}
          
        {hasControls && (
          <EditArticleDropdown article={article} disabled={this.attrs.disabled} onStartEditing={this.attrs.onStartEditing} />
        )}
      </div>
    );
  }
}