import { extend } from 'flarum/extend';
import SessionDropdown from 'flarum/components/SessionDropdown';
import Button from 'flarum/components/Button';
import Separator from 'flarum/components/Separator';

export default function() {
  extend(SessionDropdown.prototype, 'items', function (items) {
    if (!app.session.user) return;

    if(app.forum.attribute("supportTicketsInUserMenu") == true || app.forum.attribute("supportTicketsComposeInUserMenu") == true) {
      items.add('support-tickets-separator', Separator.component(), -1);
    }

    // Add 'My tickets' in the user menu?
    if(app.forum.attribute("supportTicketsInUserMenu") == true) {
      // Add default user tickets button
      const button = Button.component({
        icon: `fas fa-ticket-alt`,
        onclick: () => m.route.set(app.route("ticketsOverview")),
      }, app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.title'));
      items.add('support-tickets-personal', button, -1);
    }

    // Can compose ticket?
    if(app.forum.attribute("supportTicketsComposeInUserMenu") == true && app.forum.attribute('canComposeSupportTickets')) {
      // Compose ticket
      const ticketComposer = Button.component({
        icon: `fas fa-edit`,
        onclick: () => m.route.set(app.route("ticketComposer")),
      }, app.translator.trans('v17development-flarum-support.forum.composer.compose_ticket'));

      items.add('support-tickets-composer', ticketComposer, -1);
    }
    
    // Is admin?
    if(app.forum.attribute('canViewTickets')) {
      items.add('support-tickets-admin-seperator', Separator.component(), -1);

      // Add default user tickets button
      const adminButton = Button.component({
        icon: `fas fa-ticket-alt`,
        onclick: () => m.route.set(app.route("ticketAdminOverview")),
      }, app.translator.trans('v17development-flarum-support.forum.tickets.overview.title'));

      items.add('support-tickets-admin', adminButton, -1);
    }
  });
}
