import EventPost from 'flarum/components/EventPost';

export default class DiscussionHiddenPost extends EventPost {
  icon() {
    return this.attrs.post.content().hidden
      ? 'fas fa-eye-slash'
      : 'fas fa-eye';
  }

  description(data) {
    return this.attrs.post.content().hidden
      ? app.translator.trans('v17development-flarum-support.forum.article_events.article_hidden', data)
      : app.translator.trans('v17development-flarum-support.forum.article_events.article_public', data);
  }
}
