import EventPost from 'flarum/components/EventPost';

export default class InactiveTicketClosedPost extends EventPost {
  icon() {
    return 'far fa-clock';
  }

  description(data) {
    return app.translator.trans('v17development-flarum-support.forum.tickets.status.closed.is_auto_closed', data);
  }
}
