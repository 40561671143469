import Component from 'flarum/common/Component';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Button from 'flarum/components/Button';
import Link from 'flarum/components/Link';
import EditArticleDropdown from '../Dropdowns/EditArticleDropdown';
import humanTime from 'flarum/helpers/humanTime';

export default class KnowledgeBaseAdminOverview extends Component {
  oninit(vnode) {
    super.oninit(vnode);
  }

  view() {
    const state = this.attrs.state;
    
    let loading = null;

    if (state.discussions.length > 0 && state.isLoading()) {
      loading = LoadingIndicator.component();
    } else if (state.moreResults) {
      loading = Button.component({
        className: 'Button',
        icon: 'fas fa-chevron-down',
        onclick: state.loadMore.bind(state)
      }, app.translator.trans('core.forum.discussion_list.load_more_button'));
    }

    // No items
    if (state.empty()) {
      return (
        <div className="Support-Empty">
          <div className="Support-Clear" />
          <p>{app.translator.trans('v17development-flarum-support.forum.return_to_home')}</p>
        </div>
      );
    }

    const positiveImage = <img src={`https://twemoji.maxcdn.com/v/12.1.2/72x72/${app.forum.attribute('support_knowledge_base_rating_emoji_positive')}.png`} />;
    const neutralImage = <img src={`https://twemoji.maxcdn.com/v/12.1.2/72x72/${app.forum.attribute('support_knowledge_base_rating_emoji_neutral')}.png`} />;
    const badImage = <img src={`https://twemoji.maxcdn.com/v/12.1.2/72x72/${app.forum.attribute('support_knowledge_base_rating_emoji_bad')}.png`} />;

    return (
      <div>
        <div className={"Article-list-score Article-list-score-header"}>
          <div className={"Article-list-score-title"}>{app.translator.trans('v17development-flarum-support.forum.article.article')}</div>
          <div className={"Article-list-score-image"}>{badImage}</div>
          <div className={"Article-list-score-image"}>{neutralImage}</div>
          <div className={"Article-list-score-image"}>{positiveImage}</div>
          <div className={"Article-list-score-score"}>{app.translator.trans('v17development-flarum-support.forum.support_ratings.score')}</div>
          <div className={"Article-list-score-status"} />
          <div className={"Article-list-score-placeholder"} />
        </div>

        {state.discussions.map(article => {
          const total = article.ratings() ? article.ratings().positive + article.ratings().neutral + article.ratings().negative : 0;
          
          // Collect all points 
          // check against the total amount of potential max collected points
          const percentage = article.ratings() ? (
            (article.ratings().positive * 2) +
            article.ratings().neutral -
            (article.ratings().negative * -1)
          ) / (total * 2) * 100 : 0;

          return (
            <div className="Category-Item-Container Hide-Dropdown-Controls">
              <div className="Support-Settings-Holder">
                {(article.canRename() || article.canHide()) && <EditArticleDropdown article={article} onListPage={true} />}
              </div>

              <Link href={app.route('knowledgeBaseArticle', { id: article.slug() })} className="Category-Item Is-Sub-Item">
                <h4>{article.title() + (article.isHidden() ? ` (${app.translator.trans('v17development-flarum-support.forum.article.hidden')})` : '')}</h4>
                <div className={"Article-modified-info"}>
                  {app.translator.trans('v17development-flarum-support.forum.article.created_at', {
                    date: <i>{humanTime(article.createdAt())}</i>
                  })}

                  {article.metaLastEditedTime() && [
                    <span>
                      |
                    </span>,
                    <span>
                      {app.translator.trans('v17development-flarum-support.forum.article.last_modified', {
                        date: <i>{humanTime(article.metaLastEditedTime())}</i>
                      })}
                    </span>
                  ]}
                </div>

                <div class={"Article-list-score Article-list-inline-item"}>
                  <div class={"Article-list-score-image"}>{article.ratings() ? article.ratings().negative : 0}</div>
                  <div class={"Article-list-score-image"}>{article.ratings() ? article.ratings().neutral : 0}</div>
                  <div class={"Article-list-score-image"}>{article.ratings() ? article.ratings().positive : 0}</div>
                  <div class={"Article-list-score-score"}>{total > 0 ? `${Math.round(percentage)}%` : ''}</div>
                  <div class={"Article-list-score-status"}>
                    {(total > 0 && percentage >= 75) && (
                      positiveImage
                    )}
                    {(total > 0 && percentage >= 50 && percentage < 75) && (
                      neutralImage
                    )}
                    {(total > 0 && percentage >= 0 && percentage < 50) && (
                      badImage
                    )}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
        
        {loading && (
          <div className="SupportSearchList-loadMore">
            {loading}
          </div>
        )}
      </div>
    );
  }
}