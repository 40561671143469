import Modal from 'flarum/components/Modal';
import usernameHelper from 'flarum/helpers/username';
import ArticleModalEvent from '../Article/ArticleModalEvent';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Link from 'flarum/components/Link';

export default class ArticleAuditLogs extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.article = this.attrs.article;

    this.isLoading = true;
    this.audits = [];

    this.loadItems();
  }

  className() {
    return 'Support-Modal Support-Modal-AuditLog';
  }

  title() {
    return app.translator.trans('v17development-flarum-support.forum.modals.audit_log.title');
  }

  content() {
    return (
      <div>
        {!this.isLoading && (
          <div className={"Support-Modal-AuditLog-List"}>
            {this.audits.map(post => {
              if(post.contentType() === 'comment') return;

              if(typeof app.postComponents[post.contentType()] !== 'undefined') {
                return this.createEvent(post);
              }

              return (
                <div>Unknown action {post.contentType()}</div>
              )
            })}
            
            <ArticleModalEvent 
              icon="fas fa-medal"
              event={app.translator.trans('v17development-flarum-support.forum.modals.audit_log.article_created')} 
              eventObject={this.article}
              />
          </div>
        )}

        {!this.isLoading && this.audits.length === 0 && (
          <div className="Support-Loader">
            <span>{app.translator.trans('v17development-flarum-support.forum.modals.audit_log.no_more_events')}</span> 
          </div>
        )}
        
        
        {this.isLoading && (
          <div className="Support-Loader">
            <span>{app.translator.trans('v17development-flarum-support.forum.modals.audit_log.loading_auditlog')}</span> 
            <LoadingIndicator />
          </div>
        )}
      </div>
    );
  }

  /**
   * Generate event post
   * 
   * @param {*} post 
   */
  createEvent(post) {
    // Get post component type
    const PostComponent = app.postComponents[post.contentType()];

    const postComponentData = new PostComponent;
    postComponentData.oninit({
      attrs: {
        post
      }
    });

    // Generate post description
    const user = post.user();
    const username = usernameHelper(user);
    const data = Object.assign(postComponentData.descriptionData(), {
      user,
      username: user ? (
        <Link className="AuditLog-user" href={app.route.user(user)}>
          {username}
        </Link>
      ) : (
        username
      ),
    });

    return (
      <ArticleModalEvent 
        icon={postComponentData.icon()}
        event={postComponentData.description(data)} 
        eventObject={post}
        />
    );
  }

  /**
   * Load items
   */
  loadItems() {
    this.isLoading = true;

    app.store.find('support/articleAuditLogs', this.article.id())
      .then(data => {
        this.isLoading = false;
        this.audits = data;
    })
    .then(() => {
      m.redraw();
    })
  }
}