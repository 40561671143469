import Component from 'flarum/common/Component';
import Button from 'flarum/components/Button';
import Dropdown from 'flarum/components/Dropdown';
import extractText from 'flarum/utils/extractText';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import EditPostComposer from 'flarum/components/EditPostComposer';
import TagDiscussionModal from 'flarum/tags/components/TagDiscussionModal';
import RenameArticleModal from '../Modals/RenameArticleModal';
import ArticleAuditLogs from '../Modals/ArticleAuditLogs';
import ArticleRatingModal from '../Modals/ArticleRatingModal';

export default class EditArticleDropdown extends Component {
  oninit(vnode) {
    super.oninit(vnode);

    this.article = this.attrs.article;
    this.onListPage = this.attrs.onListPage || false;
    this.startEditingCallback = this.attrs.startEditingCallback || false;
    this.loadedPost = false;
  }

  view() {
    let buttons = [];

    // Rename article
    if(this.article.canRename()) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(RenameArticleModal, { article: this.article, redirect: !this.onListPage }),
          icon: 'fas fa-pencil-alt'
        }, app.translator.trans('v17development-flarum-support.forum.article.tools.rename_article'))
      );
      buttons.push(<li className="Dropdown-separator" />);
    }
    
    const articlePost = this.article.firstPost();

    if(articlePost) {
      // Edit article
      buttons.push(
        Button.component({
          className: 'Button',
          disabled: !articlePost.canEdit(),
          onclick: () => {
            if(this.attrs.onStartEditing) {
              this.attrs.onStartEditing();
            }else {
              m.route.set(`/knowledgebase/${this.article.slug()}?edit=true`);
            }
          },
          icon: 'fas fa-edit'
        }, app.translator.trans('v17development-flarum-support.forum.article.tools.edit_article'))
      );
    }

    // Can edit tags
    if(this.article.canTag()) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(TagDiscussionModal, { discussion: this.article }),
          icon: 'fas fa-tag'
        }, app.translator.trans('v17development-flarum-support.forum.article.tools.edit_tags'))
      );
    }

    if((app.forum.attribute('support_knowledge_base_view_ratings') || app.forum.attribute('support_knowledge_base_can_view_audit_logs')) && buttons.length > 0) {
      buttons.push(<li className="Dropdown-separator"/>);
    }
    
    // Add audit log button
    if(app.forum.attribute('support_knowledge_base_can_view_audit_logs')) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(ArticleAuditLogs, { article: this.article }),
          icon: 'fas fa-history'
        }, app.translator.trans('v17development-flarum-support.forum.article.tools.audit_log'))
      );
    }

    // View article ratings
    if(app.forum.attribute('support_knowledge_base_view_ratings')) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(ArticleRatingModal, { article: this.article }),
          icon: 'fas fa-star-half-alt'
        }, app.translator.trans('v17development-flarum-support.forum.article.tools.view_ratings'))
      );
    }

    // Hide/show/delete
    if(this.article.canHide()) {
      if(buttons.length > 0) {
        buttons.push(<li className="Dropdown-separator"/>);
      }

      // Article is hidden
      if(this.article.isHidden()) {
        // Recover article
        buttons.push(
          Button.component({
            className: 'Button',
            onclick: DiscussionControls.restoreAction.bind(this.article),
            icon: 'fas fa-eye'
          }, app.translator.trans('v17development-flarum-support.forum.article.tools.recover_article'))
        );

        // Delete article
        if(this.article.canDelete()) {
          buttons.push(
            Button.component({
              className: 'Button',
              onclick: () => {
                // Confirm deletion
                if (confirm(extractText(app.translator.trans('core.forum.discussion_controls.delete_confirmation')))) {
                  // Redirect if the current page is an knowledge base article
                  if (app.history.getCurrent().name === 'knowledgeBaseArticle') {
                    if(app.previous) {
                      app.history.back();
                    }else{
                      m.route.set(app.route('knowledgeBase'));
                    }
                  }
            
                  return this.article.delete().then(() => {
                    m.redraw();
                  });
                }            
              },
              icon: 'far fa-trash-alt'
            }, app.translator.trans('v17development-flarum-support.forum.article.tools.delete_forever'))
          );
        }
      }else{
        // Hide article
        buttons.push(
          Button.component({
            className: 'Button',
            onclick: DiscussionControls.hideAction.bind(this.article),
            icon: 'fas fa-eye-slash'
          }, app.translator.trans('v17development-flarum-support.forum.article.tools.hide_article'))
        );
      }
    }

    return Dropdown.component({
      icon: this.onListPage ? 'fas fa-ellipsis-v' : 'fas fa-cog',
      label: this.onListPage ? '' : app.translator.trans('v17development-flarum-support.forum.settings'),
      buttonClassName: `Button ${this.attrs.disabled && this.attrs.disabled === true ? 'disabled' : ''}`,
      className: this.onListPage ? '' : "Support-Dropdown-Settings",
      menuClassName: 'Dropdown-menu--right',
      onshow: () => {
        // Get post data to make sure they can edit the post
        if(articlePost && !articlePost.canEdit() && !this.loadedPost) {
          this.loadedPost = true;
          app.store
            .find('posts', this.article.firstPost() ? this.article.firstPost().id() : this.article.firstPostId())
            .then(() => {})
            .catch(() => {})
            .then(() => m.redraw())
        }     
      }
    }, buttons);
  }
}