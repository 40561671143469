import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';
import { slug } from 'flarum/utils/string';

export default class RenameTagModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);
    
    this.tag = this.attrs.currentTag;

    this.name = Stream(this.tag.name() || '');
    this.slug = Stream(this.tag.slug() || '');
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return 'Edit category name';
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('name', <div className="Form-group">
      <label>Category name:</label>
      <input className="FormControl" placeholder="Name" value={this.name()} oninput={e => {
        this.name(e.target.value);
        this.slug(slug(e.target.value));
      }}/>
    </div>, 50);

    items.add('slug', <div className="Form-group">
      <label>Category slug:</label>
      <input className="FormControl" bidi={this.slug} />
    </div>, 40);

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading
      }, 'Update')}
    </div>, -10);

    return items;
  }

  submitData() {
    return {
      name: this.name(),
      slug: this.slug()
    };
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    this.tag.save({
      name: this.name(),
      slug: this.slug()
    }).then(
      () => {
        this.hide();

        // If we need to redirect, but the knowledge base has only one tag, or it has no parent
        if(this.attrs.redirect && (!this.attrs.isMulti || (this.attrs.isMulti && !this.tag.parent()))) {
          m.route.set(app.route('knowledgeBaseCategory', { 
            category: this.tag.slug()
          }));
        }
        // We need to redirect a multi-tag knowledge base
        else if(this.attrs.redirect && this.attrs.isMulti) {
          m.route.set(app.route('knowledgeBaseMultiCategory', {
            parent_category: this.tag.parent().slug(),
            category: this.tag.slug()
          }));
        }
      },
      response => {
        this.loading = false;
        this.handleErrors(response);
      }
    );
  }
}