import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import PeacefulTagList from '../../Components/Overview/PeacefulTagList';
import sortCategoryByTag from '../../utils/sortCategoriesByTag';
import EditArticleListDropdown from '../../Components/Dropdowns/EditArticleListDropdown';
import PeacefulArticleList from '../../Components/ArticleList/PeacefulArticleList';
import sortTags from '../../utils/sortTags';
import Link from 'flarum/components/Link';
import LinkButton from 'flarum/components/LinkButton';

export default class Overview extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    app.setTitle(app.translator.trans('v17development-flarum-support.forum.knowledge_base'));

    // Get knowledge base categories
    this.knowledgeBaseTags = app.forum.attribute('support_knowledge_base_tags') || [];

    let showCategories = [];

    this.currentTag = null;
    this.subCategorySelected = false;
    this.fallbackToOneMainTag = false;
    
    // A category has been selected
    if(m.route.param().category) {
      this.currentTag = app.store.getBy("tags", "slug", m.route.param().category);

      // Make sure the tag is part of the parent category
      if(
        this.currentTag && m.route.param().parent_category  &&
        (
          // If the found tag has no parent, it means you shouldn't be here
          !this.currentTag.parent() || 
          // If the tag has a parent, but the slug doesn't match, what are you doing here?!
          (
            this.currentTag.parent() &&
            this.currentTag.parent().slug() !== m.route.param().parent_category
          )
        )
      ) {
        this.currentTag = null;
      }
      // If it's a single category
      else if(this.currentTag && m.route.param().category && !m.route.param().parent_category && 
        (
          (
            this.knowledgeBaseTags.length === 1 && 
            (
              // If no parent or the parent is not part of the knowledgebase
              !this.currentTag.parent() || (this.currentTag.parent() && this.knowledgeBaseTags.indexOf(this.currentTag.parent().id()) === -1)
            )
          ) || (
            // Multiple categories, but this tag is not part of the knowledge base
            this.knowledgeBaseTags.length > 1 && this.knowledgeBaseTags.indexOf(this.currentTag.id()) === -1
          )
        )
      ) {
        this.currentTag = null;
      }

      // Make sure the tag has been found
      if(this.currentTag) {
        // Update title if a category is selected
        if(m.route.param().category) {
          app.history.push('knowledgeBaseCategory', this.currentTag.name());

          app.setTitle(`${this.currentTag.name()} - ${app.translator.trans('v17development-flarum-support.forum.knowledge_base')}`);
          this.subCategorySelected = true;
        }

        // Get categories
        if(!this.currentTag.parent()) {
          showCategories = sortCategoryByTag(this.currentTag);
        }
      }
    }
    // No category selected, but there is only one main tag
    else if(this.knowledgeBaseTags.length === 1) {
      // Get tag
      this.currentTag = app.store.getById('tags', this.knowledgeBaseTags[0]);

      if(this.currentTag) {
        // Get categories inside this tag
        showCategories = sortCategoryByTag(this.currentTag);

        app.history.push('knowledgeBase', this.currentTag.name());
      }
    }
    // There are multiple main categories
    else{
      const validateTags = [];

      // Loop through all tags
      for(let tagId of this.knowledgeBaseTags) {
        const findKBTag = app.store.getById('tags', tagId);

        // Push tag if found
        if(findKBTag) {
          validateTags.push(findKBTag);
        }
      }

      // Multiple tags valid
      if(validateTags.length > 1) {
        showCategories = sortTags(validateTags);
      }else{
        // Only one tag valid
        showCategories = sortCategoryByTag(validateTags[0]);

        this.currentTag = validateTags[0];

        this.fallbackToOneMainTag = true;
      }

      app.history.push('knowledgeBase', app.translator.trans('v17development-flarum-support.forum.knowledge_base_overview'));
    }

    // Set main tags as categories
    this.categories = showCategories;

    // Set body class
    this.bodyClass = 'Support-Page-overview';
  }

  view() {
    return [
      <PeacefulHero />,
      <div className="Support-Block Block-CategoryFullWidth">
        <div className="container">
          <div className="SupportCategoryContainer">
            <div className="Category-Item-Container Category-No-Border">
              {(app.forum.attribute('adminUrl') || app.forum.attribute('support_knowledge_base_view_ratings')) && (
                <div className={"Support-Admin-Toolbar"}>
                  <span className={"title"}>
                    {app.translator.trans('v17development-flarum-support.forum.knowledge_base')}
                  </span>

                  <div className={"ButtonGroup"}>
                    <LinkButton 
                      href={"/knowledgebase/admin"} 
                      className={"Button"} 
                      icon={"fas fa-toolbox"}>
                      {app.translator.trans('v17development-flarum-support.forum.admin_overview')}
                    </LinkButton>

                    {/* Category management */}
                    {this.currentTag && app.forum.attribute('adminUrl') && (
                      <EditArticleListDropdown 
                        currentTag={this.currentTag} 
                        multiCategory={this.knowledgeBaseTags.length > 1 } 
                        redirectAfterRename={this.subCategorySelected}
                        noContainer={true}
                        />
                    )}
                  </div>
                </div>
              )}

              {/* Category meta data */}
              {this.currentTag && (
                <div className="Category-Item Category-Head">
                  <h4>
                    <span><i className={ this.currentTag.icon() ? this.currentTag.icon() : 'fas fa-bookmark' } /></span>{' '}
                    {this.currentTag.name()}
                    
                    {/* Navigate back to main overview */}
                    {((this.knowledgeBaseTags.length > 1 && !m.route.param().parent_category) || (this.knowledgeBaseTags.length === 1 && m.route.param().category)) && !this.fallbackToOneMainTag && (
                      <span>
                        <span className="Support-splitter">-</span>
                        <Link href={app.route('knowledgeBase')} className="Support-Return-Button">{app.translator.trans('v17development-flarum-support.forum.overview.back_to_main_categories')}</Link>
                      </span>
                    )}

                    {/* Navigate back to previous category */}
                    {m.route.param().parent_category && this.currentTag.parent() && this.knowledgeBaseTags.length > 1 && (
                      <span>
                        <span className="Support-splitter">-</span>
                        <Link 
                          href={app.route('knowledgeBaseCategory', {
                            category: this.currentTag.parent().slug()
                          })}
                          className="Support-Return-Button">
                            {app.translator.trans('v17development-flarum-support.forum.overview.back_to', {
                              category: this.currentTag.parent().name()
                            })}
                        </Link>
                      </span>
                    )}
                  </h4>

                  <p>{ this.currentTag.description() }</p>
                </div>
              )}

              <div className="Support-Clear" />
            </div>

            {/* Render taglist */}
            {this.categories.length > 0 && (
              <PeacefulTagList 
                tags={this.categories}
                parent={this.currentTag}
                isMultiCategory={this.knowledgeBaseTags.length > 1}
                />
            )}

            {/* Show article list */}
            {this.currentTag && this.categories.length === 0 && (
              <PeacefulArticleList state={app.discussions} currentTag={this.currentTag} />
            )}

            {/* No tags found. Not set up propperly */}
            {this.categories.length === 0 && !this.subCategorySelected && (
              <div className="Support-Empty">
                <div className="Support-Clear" />
                <p>{app.translator.trans('v17development-flarum-support.forum.overview.no_categories_found')}</p>
                <p><Link href={app.route('knowledgeBase')}>{app.translator.trans('v17development-flarum-support.forum.return_to_home')}</Link></p>
              </div>
            )}
          </div>

          <div className="All-Items-Container">
            <Link
              className="All-Items"
              href={app.route("index")}
            >{app.translator.trans('v17development-flarum-support.forum.ask_a_question_on_forums')}</Link>
          </div>
        </div>
      </div>
    ];
  }
}