import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class SupportTicket extends mixin(Model, {
    discussion: Model.hasOne('discussion'),
    privateNote: Model.attribute('private_note'),
    answer: Model.hasOne('post'),
    answeredAt: Model.attribute('answeredAt'),
    ticketStatus: Model.attribute('ticketStatus'),
    ticketPriority: Model.attribute('ticketPriority'),
    assignees: Model.hasMany('assignees'),
    language: Model.hasOne('language'),
    remindedCount: Model.attribute('remindedCount')
}) {
  apiEndpoint() {
    return '/support/ticket' + (this.exists ? '/' + this.data.id : '');
  }
}