import EventPost from 'flarum/components/EventPost';

export default class DiscussionModifiedPost extends EventPost {
  icon() {
    return 'far fa-edit';
  }

  description(data) {
    return app.translator.trans('v17development-flarum-support.forum.article_events.article_modified', data);
  }
}
