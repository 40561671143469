import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import humanTime from 'flarum/helpers/humanTime';
import Alert from 'flarum/components/Alert'

export default class ArticleRatingModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.article = this.attrs.article;

    this.loaded = false;
    this.isLoading = true;

    this.show = 'all';
    this.allRatings = [];
    this.ratingsWithFeedback = [];

    // Currently showing data
    this.currentlyShowing = [];

    // Deleting items
    this.pendingDelete = [];

    // Calculations
    this.percentage_positive = 0;
    this.percentage_neutral = 0;
    this.percentage_negative = 0;

    this.loadItems();
  }

  className() {
      return 'Modal--large Support-Modal Support-Rating-Modal';
  }

  title() {
      return app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.title');
  }

  content() {
    return (
      <div className="Modal-body">
        {this.isLoading && (
          <div 
            style={{
              textAlign: 'center',
              padding: '40px 20px'
            }}>
            <LoadingIndicator size={"large"} />

            <p style={{ marginTop: '35px' }}>{app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.loading_ratings')}</p>
          </div>
        )}

        {!this.isLoading && (
          <div>
            <div className={"Support-Ratings-Percentages"}>
              <a 
                className={`rating-item ${this.show === 'positive' ? 'selected' : ''}`}
                href="#" 
                onclick={e => {
                  e.preventDefault();
    
                  if(this.show !== 'positive') {
                    this.show = 'positive';
                    this.currentlyShowing = this.allRatings.filter(rating => rating.points() === 2);
                  }else{
                    this.reset();
                  }
                }}>
                <img src={"https://twemoji.maxcdn.com/v/12.1.2/72x72/1f60d.png"} /> <span>{this.percentage_positive}%</span>
              </a>
              <a
                className={`rating-item ${this.show === 'neutral' ? 'selected' : ''}`}
                href="#" 
                onclick={e => {
                  e.preventDefault();
    
                  if(this.show !== 'neutral') {
                    this.show = 'neutral';
                    this.currentlyShowing = this.allRatings.filter(rating => rating.points() === 1);
                  }else{
                    this.reset();
                  }
                }}>
                <img src={"https://twemoji.maxcdn.com/v/12.1.2/72x72/1f603.png"} /> <span>{this.percentage_neutral}%</span>
              </a>
              <a
                className={`rating-item ${this.show === 'negative' ? 'selected' : ''}`}
                href="#" 
                onclick={e => {
                  e.preventDefault();
    
                  if(this.show !== 'negative') {
                    this.show = 'negative';
                    this.currentlyShowing = this.allRatings.filter(rating => rating.points() === 0);
                  }else{
                    this.reset();
                  }
                }}>
                <img src={"https://twemoji.maxcdn.com/v/12.1.2/72x72/1f614.png"} /> <span>{this.percentage_negative}%</span>
              </a>
            </div>
            <div className={"Support-RatingDataTable"}>
              <table width="100%">
                <thead>
                  <td width="150">{app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.username')}</td>
                  <td width="70" className={"text-centered"}>{app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.rating')}</td>
                  <td width="150">{app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.rated_on')}</td>
                  <td>{app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.feedback')}</td>
                  <td width="30"></td>
                </thead>
                <tbody>
                  {this.currentlyShowing.length === 0 && (
                    <tr>
                      <td colspan="5" className={"text-centered"} style={{ padding: '20px' }}>{app.translator.trans(`v17development-flarum-support.forum.modals.article_ratings.${this.currentlyShowing.length === 0 && this.allRatings.length === 0 ? 'article_not_rated' : 'no_ratings_found'}`)}</td>
                    </tr>
                  )}
                  {this.currentlyShowing.map(rating => {
                    if(!rating.exists) return;

                    const isPendingDelete = this.pendingDelete.indexOf(rating) >= 0;

                    return (
                      <tr>
                        <td>{rating.user() ? rating.user().username() : `Guest (${rating.userIp()})`}</td>
                        <td className={"text-centered"}>{rating.points()}</td>
                        <td>{humanTime(rating.createdAt())}</td>
                        <td>{rating.feedback() ? rating.feedback() : ''}</td>
                        <td>
                          <Button className={"Button"} disabled={isPendingDelete} onclick={() => {
                            if(confirm(app.translator.trans('v17development-flarum-support.forum.modals.article_ratings.confirm_delete_rating'))) {
                              this.pendingDelete.push(rating);
                              this.delete(rating);
                            }
                          }}>
                            <i className={isPendingDelete ? "fas fa-spin fa-spinner" : "far fa-trash-alt"} />
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * Reset view
   */
  reset() {
    this.show = 'all';
    this.currentlyShowing = this.allRatings;
  }

  /**
   * Load items
   */
  loadItems() {
    this.isLoading = true;

    app.store.find('support/ratings', this.article.id())
      .then(data => {
        this.isLoading = false;
        this.allRatings = data;
        this.currentlyShowing = this.allRatings;
        this.calculatePercentages(data);
    })
    .then(() => {
      m.redraw();
    })
  }

  /**
   * Delete rating
   * @param {Number} id 
   */
  delete(rating) {
    app.request({
      method: "DELETE",
      url: `${app.forum.attribute('apiUrl')}/support/rating/${rating.id()}`
    })
      .then(() => {
        const found = this.currentlyShowing.indexOf(rating);

        delete this.currentlyShowing[found];

        app.alerts.show(Alert, {
          type: 'success'
        }, "Vote has been removed");
      })
      .then(() => {
        m.redraw();
      })
  }

  /**
   * Calculate percentages
   * 
   * @param {} data 
   */
  calculatePercentages(data) {
    const total = data.length;
    let positive = 0;
    let neutral = 0;
    let negative = 0;

    data.map(obj => {
      if(obj.points() === 2) {
        positive += 1;
      }else if(obj.points() === 1) {
        neutral += 1;
      }else if(obj.points() === 0) {
        negative += 1;
      }
    });

    this.percentage_positive = Math.round(positive / total * 100) || 0;
    this.percentage_neutral = Math.round(neutral / total * 100) || 0;
    this.percentage_negative = Math.round(negative / total * 100) || 0;
  }
}