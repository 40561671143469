import { extend } from 'flarum/extend';
import Model from 'flarum/Model';
import Discussion from 'flarum/models/Discussion';
import Overview from "./pages/KnowledgeBase/Overview";
import Article from "./pages/Knowledgebase/Article";
import DiscussionHiddenPost from "./Components/DiscussionEventPosts/DiscussionHiddenPost";
import DiscussionModifiedPost from "./Components/DiscussionEventPosts/DiscussionModifiedPost";
import Search from "./pages/Knowledgebase/Search";
import Redirector from "./utils/redirector";
import SupportRating from "../common/Models/SupportRating";
import extendTagOverview from "./utils/extendTagOverview";
import ComposeArticle from "./pages/Knowledgebase/ComposeArticle";
import addUnansweredTicketsDropdown from "./addUnansweredTicketsDropdown";
import SupportTicket from "../common/Models/SupportTicket";
import OpenTicketListState from "./states/OpenTicketListState";
import ComposeTicket from "./pages/Tickets/ComposeTicket";
import SupportTicketLanguage from "../common/Models/SupportTicketLanguage";
import OverviewUser from "./pages/Tickets/OverviewUser";
import OverviewAgent from "./pages/Tickets/OverviewAgent";
import addTicketsOverviewToSessionDropdown from './addTicketsOverviewToSessionDropdown'
import Ticket from "./pages/Tickets/Ticket";
import SupportTicketAssignee from "../common/Models/SupportTicketAssignee";
import InactiveTicketClosedPost from './Components/DiscussionEventPosts/InactiveTicketClosedPost';
import DiscussionConvertedToTicketPost from './Components/DiscussionEventPosts/DiscussionConvertedToTicketPost';
import addConvertToTicketControls from './addConvertToTicketControls';
import AdminPage from './pages/Knowledgebase/AdminPage';
import DiscussionListState from 'flarum/states/DiscussionListState';

// Register Knowledge base endpoints
app.initializers.add('v17development-flarum-support', app => {
  // Knowledge base overview
  app.routes.knowledgeBase = { path: '/knowledgebase', component: Overview };

  // Add the knowledge base home
  app.routes.knowledgeBaseHome = { path: '/knowledgebase/home', component: Overview };

  // Add the knowledge base composer
  app.routes.knowledgeBaseComposer = { path: '/knowledgebase/compose', component: ComposeArticle };

  // Category
  app.routes.knowledgeBaseCategory = { path: '/knowledgebase/category/:category', component: Overview };

  // Search
  app.routes.knowledgeBaseSearch = { path: '/knowledgebase/search', component: Search };

  // Add the knowledge base admin overview
  app.routes.knowledgeBaseAdmin = { path: '/knowledgebase/admin', component: AdminPage };

  // Category
  app.routes.knowledgeBaseMultiCategory = { path: '/knowledgebase/category/:parent_category/:category', component: Overview };

  // Knowledge base item (permalink)
  app.routes.knowledgeBaseArticle = { path: '/knowledgebase/:id', component: Article };

  // Redirect to knowledge base article
  app.route.knowledgeBaseArticle = article => app.route('knowledgeBaseArticle', { id: article.id() });

  // Tickets overview
  app.routes.ticketsOverview = { path: '/tickets', component: OverviewUser };

  // Ticket composer
  app.routes.ticketComposer = { path: '/tickets/create', component: ComposeTicket };

  // Ticket admin view
  app.routes.ticketAdminOverview = { path: '/tickets/admin', component: OverviewAgent };

  // Tickets view
  app.routes.ticketsView = { path: '/tickets/view/:id', component: Ticket };

  // Redirect to ticket
  app.route.ticket = ticket => app.route('ticketsView', { id: ticket.supportTicket() ? ticket.supportTicket().id() : ticket.id() });
  
  // Add notification logs for knowledge base articles
  app.postComponents.supportDiscussionHidden = DiscussionHiddenPost;
  app.postComponents.supportDiscussionPostModified = DiscussionModifiedPost;
  app.postComponents.supportInactiveTicketClosed = InactiveTicketClosedPost;
  app.postComponents.supportDiscussionConvertedToTicket = DiscussionConvertedToTicketPost;

  // Add support extension models
  app.store.models.supportRating = SupportRating;
  app.store.models.supportTickets = SupportTicket;
  app.store.models.supportTicketLanguages = SupportTicketLanguage;
  app.store.models.supportTicketAssignees = SupportTicketAssignee;

  // Support ticket notifications
  // app.notificationComponents.repliedToSupportTicket = RepliedToSupportTicket;

  // extend(NotificationGrid.prototype, 'notificationTypes', function (items) {
  //   items.add('repliedToSupportTicket', {
  //     name: 'repliedToSupportTicket',
  //     icon: 'far fa-thumbs-up',
  //     label: app.translator.trans('flarum-likes.forum.settings.notify_post_liked_label')
  //   });
  // });

  // Update discussion relation
  Discussion.prototype.supportTicket = Model.hasOne('supportTickets');
  Discussion.prototype.canConvertIntoTicket = Model.attribute('canConvertIntoTicket');
  Discussion.prototype.metaLastEditedTime = Model.attribute('metaLastEditedTime');
  Discussion.prototype.ratings = Model.attribute('ratings');

  app.supportOpenTicketsState = new OpenTicketListState(app);

  // Add listener for tags page and support tickets
  Redirector();

  extendTagOverview();

  addUnansweredTicketsDropdown();

  addTicketsOverviewToSessionDropdown();

  addConvertToTicketControls();

  // Add required firstPost
  extend(DiscussionListState.prototype, 'requestParams', function(params) {
    if(app.current.matches(Overview)) {
      params.include.push('firstPost');
    }
  });
});
