import Component from 'flarum/common/Component';
import UserCard from 'flarum/components/UserCard';
import Link from 'flarum/components/Link';

export default class ArticleAuthors extends Component {
  view() {
    return (
      <div className="Article-Authors">
        {app.translator.trans('v17development-flarum-support.forum.article.authors')}: {' '}

        {/* Map through all authors */}
        {this.attrs.authors.map((author, key) => {
          return (
            <span>
              {key > 0? ', ' : ''}

              <span className="PostUser">
                <Link href={app.route('user', { username: author.username() })}>{author.displayName()}</Link>
                <UserCard user={author} className="UserCard--popover" controlsButtonClassName="Dropdown-toggle Button Button--icon Button--flat" />
              </span>
            </span>
          )
        })}
      </div>
    );
  }
}