import Component from 'flarum/common/Component';
import Link from 'flarum/components/Link';

export default class ArticleReturnLink extends Component {
  view() {
    const className = `Support-Return-Button ${this.attrs.disabled ? 'disabled' : ''}`;

    // Does the user has a previous page?
    if(app.history.getPrevious() && (app.history.getPrevious().name === 'knowledgeBaseCategory' || app.history.getPrevious().name === 'knowledgeBaseMultiCategory')) {
      return (
        <Link className={className} href={app.history.getPrevious().url}>
          {app.translator.trans('v17development-flarum-support.forum.overview.back_to', {
            category: app.history.getPrevious().title
          })}
        </Link>
      );
    }

    // Retrun to search page
    if(app.history.getPrevious() && app.history.getPrevious().name === 'knowledgeBaseSearchPage') {
      return (
        <Link className={className} href={app.history.getPrevious().url}>
          {app.translator.trans('v17development-flarum-support.forum.search.back_to_search_results')}
        </Link>
      );
    }

    // Return to knowledge base
    return (
      <Link className={className} href={app.route('knowledgeBase')}>
        {app.translator.trans('v17development-flarum-support.forum.overview.back_to_overview')}
      </Link>
    );
  }
}