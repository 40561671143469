import Component from 'flarum/common/Component';
import Link from 'flarum/components/Link';
import EditArticleListDropdown from '../Dropdowns/EditArticleListDropdown';

export default class PeacefulTagList extends Component {
  view() {
    return (
      <div>
        {this.attrs.tags.map(tag => {
          if(!tag) return null;
          
          const url = 
            this.attrs.isMultiCategory && this.attrs.parent ? 
              app.route('knowledgeBaseMultiCategory', { 
                parent_category: this.attrs.parent.slug(), 
                category: tag.slug() 
              }) : 
              app.route('knowledgeBaseCategory', { 
                category: tag.slug() 
              });
          
          return (
            <div className="Category-Item-Container Hide-Dropdown-Controls">
              {tag.canStartDiscussion() ? <EditArticleListDropdown currentTag={tag} redirectAfterRename={false} noCreateArticle={false} /> : ''}

              <Link href={url} className="Category-Item">
                <h4><span>{tag.icon() ? <i className={ tag.icon() } /> : <i className="fas fa-bookmark" />}</span> {tag.name()}</h4>

                <p>{tag.description()}</p>

                <span className="MoreArticles">{tag.discussionCount() } {tag.discussionCount() === 1 ? app.translator.trans("v17development-flarum-support.forum.article.article") : app.translator.trans("v17development-flarum-support.forum.article.articles")}</span>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}