import Component from 'flarum/common/Component';
import Button from 'flarum/components/Button';
import Dropdown from 'flarum/components/Dropdown';
import CloseTicketModal from '../Modals/CloseTicketModal';
import UpdateTicketStatusModal from '../Modals/UpdateTicketStatusModal';
import UpdateTicketSubjectModal from '../Modals/UpdateTicketSubjectModal';

export default class SupportTicketDropdown extends Component {
  view() {
    const discussion = this.attrs.ticket.discussion();
    let buttons = [];

    if(!this.attrs.ticketView) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => m.route.set(app.route("ticketsView", { id: discussion.id() })),
          icon: 'far fa-eye'
        }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.view_ticket'))
      );

      buttons.push(
        Button.component({
          className: 'Button',
          disabled: true,
          onclick: () => {},
          icon: 'fas fa-user-plus'
        }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.assign_me'))
      );

      // buttons.push(
      //   Button.component({
      //     className: 'Button',
      //     onclick: () => {},
      //     icon: 'fas fa-user-times'
      //   }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.remove_me'))
      // );
    }

    // Rename ticket
    if(discussion.canRename()) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(UpdateTicketSubjectModal, { ticket: discussion, redirect: false }),
          icon: 'fas fa-pencil-alt'
        }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.update_subject'))
      );
    }

    // Ticket status
    buttons.push(
      Button.component({
        className: 'Button',
        onclick: () => app.modal.show(UpdateTicketStatusModal, { ticket: this.attrs.ticket }),
        icon: 'fas fa-wrench'
      }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.update_status'))
    );

    // Ticket 
    buttons.push(
      Button.component({
        className: 'Button',
        onclick: () => app.modal.show(CloseTicketModal, { ticket: this.attrs.ticket }),
        icon: 'fas fa-times'
      }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.close_ticket'))
    );

    return Dropdown.component({
      icon: this.attrs.ticketView ? '' : 'fas fa-ellipsis-v',
      label: this.attrs.ticketView ? 'Ticket settings' : '',
      buttonClassName: `Button`,
      menuClassName: 'Dropdown-menu--right'
    }, buttons);
  }
}