import Component from 'flarum/common/Component';
import avatar from 'flarum/helpers/avatar';

export default class TicketAssignee extends Component {
  view() {
    return avatar(this.attrs.data.user());
  }

  oncreate(vnode) {
    super.oncreate(vnode);

    this.$().tooltip({
      title: () => this.attrs.data.user().displayName()
    });
  }
}