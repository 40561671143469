import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

export default class LeftArticleEditor extends Modal {
  className() {
    return "Modal--small";
  }

  title() {
    return this.attrs.title ? this.attrs.title : app.translator.trans('v17development-flarum-support.forum.article.tools.edit_article');
  }

  content() {
    return (
      <div className={"Modal-body"}>
        <p style={{ marginBottom: '30px' }}>{app.translator.trans('core.forum.composer_edit.discard_confirmation')}</p>

        {Button.component({
          type: 'submit',
          className: 'Button Button--primary SupportModal-save',
          onclick: () => {
            this.attrs.onSave();
            this.hide();
          }
        }, app.translator.trans('core.forum.composer_edit.submit_button'))}

        {Button.component({
          type: 'submit',
          className: 'Button SupportModal-save',
          onclick: () => this.hide()
        }, app.translator.trans('v17development-flarum-support.forum.discard'))}
      </div>
    );
  }

  onsubmit(e) {
    e.preventDefault();
  }
}