import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';

export default class RenameArticleModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.article = this.attrs.article;

    this.name = Stream(this.article.title() || '');

    this.redirect = this.attrs.redirect;
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return 'Rename article';
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('name', <div className="Form-group">
      <label>Article name:</label>
      <input className="FormControl" placeholder="Article name" bidi={this.name} />
    </div>, 50);

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading
      }, 'Update')}
    </div>, -10);

    return items;
  }

  submitData() {
    return {
      title: this.name()
    };
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    this.article.save(this.submitData())
      .then(() => {
        this.hide();

        // Redirect
        if(this.redirect) {
          const url =  `/knowledgebase/${this.article.slug()}`;

          m.route.set(url, true);
          window.history.replaceState(null, document.title, url);
        }
      }, response => {
        this.loading = false;
        this.handleErrors(response);
      }
    );
  }
}