import Component from 'flarum/common/Component';
import ArticleReturnLink from './Article/ArticleReturnLink';

export default class PageNotFound extends Component {
  view() {
    return (
      <div className="Page-Not-Found">
        <h4>{app.translator.trans('v17development-flarum-support.forum.not_found.title')}</h4>
        <p>{app.translator.trans('v17development-flarum-support.forum.not_found.description')}</p>

        <p>{app.translator.trans('v17development-flarum-support.forum.not_found.solution')}</p>

        <ArticleReturnLink />
      </div>
    );
  }
}