import sortTags from "./sortTags";

export default function sortCategoryByTag(_tag) {
  // Get all tags
  const tags = app.store.all('tags');

  let categories = [];

  // Sort tags
  sortTags(tags)
    .filter(tag => (tag.isChild() && tag.parent().id() === _tag.id()))
    .forEach(foundTag => categories.push(foundTag));

  return categories;
}