import Component from 'flarum/common/Component';
import humanTime from 'flarum/helpers/humanTime';

export default class ArticleModalEvent extends Component {
  view() {
    return (
      <div className={"Support-AuditLog-List-Item"}>
        <div className={"Support-AuditLog-List-Item-icon"}>
          <i className={this.attrs.icon} />
        </div>
        <div className={"Support-AuditLog-List-Item-event"}>
          {this.attrs.event}
        </div>
        <div className={"Support-AuditLog-List-Item-date"}>
          {humanTime(this.attrs.eventObject.createdAt())}
        </div>
      </div>
    );
  }
}