import Component from 'flarum/common/Component';

class TicketStatusTag extends Component {
  view() {
    const status = getTicketStatus(this.attrs.ticket);

    // Return component
    return (
      <div className={`Flarum-Support-status Flarum-Support-status--${status}`} title={app.translator.trans(`v17development-flarum-support.forum.tickets.status.${status}.description`)}>
        {app.translator.trans(`v17development-flarum-support.forum.tickets.status.${status}.title`)}
      </div>
    )
  }

  oncreate(vnode) {
    super.oncreate(vnode);

    this.$().tooltip({
      delay: 250,
      placement: this.attrs.tooltipPosition ? this.attrs.tooltipPosition : 'top'
    });
  }
}

// Get status
function getTicketStatus(ticket) {
  let status = 'open';

  if(ticket.ticketStatus() === 0) {
    status = 'new';
  }else if(ticket.ticketStatus() === 2) {
    status = 'pending';
  }else if(ticket.ticketStatus() === 3 && ticket.answer()) {
    status = 'solved';
  }else if(ticket.ticketStatus() === 3) {
    status = 'closed';
  }else if(ticket.ticketStatus() === 4) {
    status = 'on_hold';
  }else if(ticket.ticketStatus() === 5) {
    status = 'duplicate';
  }

  return status;
}

export {
  TicketStatusTag,
  getTicketStatus
}