export default class OpenTicketListState {
  constructor(app) {
    this.app = app;
    
    this.loading = false;

    this.cache = [];
  }

  // Load tickets
  load(force = false) {
    if (!force && this.cache.length && !this.app.session.user.attribute('openTicketsCount')) {
      return;
    }

    this.loading = true;
    m.redraw();
    
    // Reset cache
    this.cache = [];

    this.app.store.find('support/tickets?type[]=0&type[]=1')
      .then(tickets => {
        this.cache = tickets;
        this.app.session.user.pushAttributes({ openTicketsCount: tickets.length });
      })
      .catch(() => { })
      .then(() => {
        this.loading = false;
        m.redraw();
      });
  }
  
  // Mark as read
  markAsRead() {
    if (this.cache.length === 0) return;

    this.app.session.user.pushAttributes({ unreadTicketsCount: 0 });

    this.app.request({
      url: this.app.forum.attribute('apiUrl') + '/support/tickets/read',
      method: 'POST',
    });
  }
}
  