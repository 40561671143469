import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';

export default class EditTagIconModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);
    
    this.tag = this.attrs.currentTag;

    this.icon = Stream(this.tag.icon() || '');
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return 'Edit category icon';
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('icon', <div className="Form-group">
      <label>Category icon:</label>

      <div style="position: relative;">
        <input className="FormControl" placeholder="fas fa-bookmark" bidi={this.icon} />
        <i className={this.icon() || 'fas fa-bookmark'} style="position: absolute; top: 8px; right: 15px; font-size: 20px;" />
      </div>
    </div>, 10);

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading
      }, 'Update')}
    </div>, -10);

    return items;
  }

  submitData() {
    return {
      icon: this.icon()
    };
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    this.tag.save(this.submitData()).then(
      () => {
        this.hide();
        m.redraw();
      },
      response => {
        this.loading = false;
        this.handleErrors(response);
      }
    );
  }
}