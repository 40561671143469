import { extend } from 'flarum/extend';
import app from 'flarum/app';
import HeaderSecondary from 'flarum/components/HeaderSecondary';
import UnansweredTicketsDropdown from './Components/Dropdowns/UnansweredTicketsDropdown';

export default function() {
  extend(HeaderSecondary.prototype, 'items', function(items) {
    if (app.forum.attribute('supportTicketsEnabled') && app.forum.attribute('canViewTickets')) {
      items.add('openTickets', <UnansweredTicketsDropdown state={app.supportOpenTicketsState} />, 20);
    }
  });
}
