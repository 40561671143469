import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';
import LanguagesData from "countries-list/dist/languages.json";
import dlv from 'dlv';

export default class SupportTicketLanguage extends mixin(Model, {
  code: Model.attribute('code'),
  notice: Model.attribute('notice'),
  isVisible: Model.attribute('isVisible'),
  isEnabled: Model.attribute('isEnabled'),

  name: function() {
    const lang = dlv(LanguagesData, this.code(), {});

    return lang.name || this.code();
  },

  native: function() {
    const lang = dlv(LanguagesData, this.code(), {});

    return lang.native || this.code();
  },

  fullName: function() {
    const lang = dlv(LanguagesData, this.code(), {});

    return lang ? `${lang.native} (${lang.name})` : this.code();
  },
}) {
  apiEndpoint() {
    return '/support/tickets/languages' + (this.exists ? '/' + this.data.id : '');
  }
}