import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import TicketOverviewAgencyTicketRow from '../../Components/TicketOverviewAgencyTicketRow/TicketOverviewAgencyTicketRow';
import filterTickets from '../../utils/filterTickets';
import Button from 'flarum/components/Button';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import LogInModal from 'flarum/components/LogInModal';

export default class OverviewAgent extends Page {
  oninit(vnode) {
    super.oninit(vnode);
    
    // Redirect if support tickets are disabled
    if(app.forum.attribute('supportTicketsEnabled') == false) {
      m.route.set(app.route("knowledgeBase"));
      return;
    };

    this.loaded = false;

    this.data = {
      isLoading: true,
      hasMore: false,
      filter: localStorage.ticketsFilter && Array.isArray(localStorage.ticketsFilter.split(",")) ? localStorage.ticketsFilter.split(",").map(ob => parseInt(ob)) : [0, 1, 4],
      list: []
    };

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.tickets.overview.title')} - ${app.translator.trans('v17development-flarum-support.forum.support_tickets')}`);
  
    if(!app.session.user) {
      setTimeout(() => app.modal.show(LogInModal), 100)
      
      return;
    } else if (!app.forum.attribute('canViewTickets')) {
      m.route.set(app.route("ticketsOverview"));
      return;
    }

    // Open tickets
    this.loadData(true);
  }

  loadData(firstLoad) {
    this.data.isLoading = true;
    m.redraw();
    
    let url = ``;

    if(firstLoad) {
      const mapFilter = this.data.filter.map(filter => `type[]=${filter}`);

      url = `support/tickets?${mapFilter.join("&")}`;
      this.data.list = [];
    }else{
      url = this.data.hasMore.replace(app.forum.attribute('apiUrl'), "");
    }

    // Load data
    app.store.find(url)
      .then(data => {
        const list = this.data.list;

        // Add new data to the list
        data.map(ticket => list.push(ticket));

        this.data = {
          isLoading: false,
          hasMore: data.payload.links && data.payload.links.next ? data.payload.links.next : false,
          filter: this.data.filter,
          list
        };
      })
      .catch(() => {})
      .then(() => m.redraw());
  }

  view() {
    // Do not load anything if the user is not logged in
    if(!app.session.user) {
      return <PeacefulHero />;
    }

    const open = filterTickets(this.data.list, this.data.filter);

    return (
      <div className={"Flarum-Support-TicketOverview"}>
        <PeacefulHero hideContent />
        <div className="Support-Block Block-CategoryFullWidth Support-Ticket">
          <div className="container">
            <div className="SupportCategoryContainer">
              <div className="Category-Item-Container Category-No-Border">
                <div className="Category-Item">
                  <div className={"ButtonGroup"} style={{ float: 'right' }}>
                    {/* {Button.component({
                      className: 'Button',
                      onclick: () => m.route.set(app.route("ticketComposer")),
                      icon: 'fas fa-edit',
                    }, app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.title'))} */}
                  </div>

                  <h4>
                    <span><i className='fas fa-ticket-alt' /></span>
                    {app.translator.trans('v17development-flarum-support.forum.tickets.overview.title')}
                  </h4>

                  <p>{app.translator.trans('v17development-flarum-support.forum.tickets.overview.description')}</p>
                </div>

                <div className={"Flarum-Support-Tickets-FilterMenu"}>
                  {Button.component({
                    className: `Button ${this.data.filter.length === 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(null),
                    icon: 'fas fa-list-ul',
                  }, app.translator.trans('v17development-flarum-support.forum.tickets.overview.all_tickets'))}

                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(0) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(0),
                    icon: 'fas fa-life-ring',
                  }, app.translator.trans(`v17development-flarum-support.forum.tickets.status.new.title`))}
                  
                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(1) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(1),
                    icon: 'fas fa-ticket-alt',
                  }, app.translator.trans(`v17development-flarum-support.forum.tickets.status.open.title`))}
                  
                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(2) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(2),
                    icon: 'far fa-copy',
                  }, app.translator.trans(`v17development-flarum-support.forum.tickets.status.pending.title`))}
                  
                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(3) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(3),
                    icon: 'fas fa-flag-checkered',
                  }, app.translator.trans(`v17development-flarum-support.forum.tickets.status.closed.title`))}
                  
                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(4) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(4),
                    icon: 'far fa-pause-circle',
                  }, `${app.translator.trans(`v17development-flarum-support.forum.tickets.status.on_hold.title`)} ${app.session.user.attribute("onHoldTicketsCount") > 0 ? `(${app.session.user.attribute("onHoldTicketsCount")})` : ''}`)}
                  
                  {Button.component({
                    className: `Button ${this.data.filter.indexOf(5) >= 0 ? 'item-selected' : ''}`,
                    onclick: () => this.filter(5),
                    icon: 'far fa-copy',
                  }, app.translator.trans(`v17development-flarum-support.forum.tickets.status.duplicate.title`))}

                  {Button.component({
                    className: `Button SupportRefreshButton`,
                    disabled: this.data.isLoading,
                    onclick: () => this.loadData(true),
                    title: "Refresh",
                    icon: 'fas fa-sync',
                  })}
                  <div className="Support-Clear" />
                </div>

                {/* Open tickets */}
                <div className={"Flarum-Support-Tickets-overview"}>

                  <div className={"Flarum-Support-Tickets-Overview-data"}>
                    <div className={"Flarum-Support-Tickets-Overview-data-header"}>
                      <span className={"Flarum-Support-Tickets-Overview-data--id"}>#</span>
                      <span className={"Flarum-Support-Tickets-Overview-data--info"}>{app.translator.trans('v17development-flarum-support.forum.tickets.ticket')}</span>
                      <span className={"Flarum-Support-Tickets-Overview-data--date"}>{app.translator.trans('v17development-flarum-support.forum.tickets.opened_on')}</span>
                      <span className={"Flarum-Support-Tickets-Overview-data--agents"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.assignees.title')}</span>
                      <span className={"Flarum-Support-Tickets-Overview-data--status"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.status.title')}</span>
                      <span className={"Flarum-Support-Tickets-Overview-data--settings"}></span>
                    </div>

                    {!this.data.isLoading && open.length === 0 && (
                      <div className={"Flarum-Support-No-tickets"}>
                        <p>{app.translator.trans('v17development-flarum-support.forum.tickets.overview.no_tickets')}</p>
                      </div>
                    )}

                    {open.map(ticket => {
                      return (
                        <TicketOverviewAgencyTicketRow ticket={ticket} />
                      );
                    })}

                    {this.data.isLoading && (
                      <LoadingIndicator />
                    )}

                    {this.data.hasMore !== false && (
                      <div className={"load-more-tickets"}>
                        {Button.component({
                          className: 'Button',
                          onclick: () => this.loadData(false),
                          icon: 'far fa-arrow-alt-circle-down',
                          disabled: this.data.isLoading
                        }, app.translator.trans('v17development-flarum-support.forum.tickets.load_more.title'))}
                      </div>
                    )}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  filter(filterOn = null) {
    if(this.data.isLoading) return;

    // Reset filter
    if(filterOn === null) {
      if(this.data.filter.length === 0) return;

      this.data.filter = [];
      delete localStorage.ticketsFilter;

      this.loadData(true);

      return;
    }

    let filterArray = this.data.filter;

    const exitsInArray = filterArray.indexOf(filterOn);

    // Add if not exists
    if(exitsInArray === -1) {
      filterArray.push(filterOn);
    }else{
      filterArray.splice(exitsInArray, 1);
    }

    this.data.filter = filterArray;
    localStorage.ticketsFilter = filterArray;

    this.loadData(true);
  }
}