import Component from 'flarum/common/Component';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Button from 'flarum/components/Button';
import Alert from 'flarum/components/Alert'
import LogInModal from 'flarum/components/LogInModal';
import Stream from 'flarum/utils/Stream';

export default class ArticleRating extends Component {
  oninit(vnode) {
    super.oninit(vnode);
    this.feedbackProcess = 'rating';
    this.chosenFeedbackPoints = 0;
    this.isUpdating = false;
    this.cachedFeedback = false;

    // If localstorage
    if(window.localStorage) {
      const rated = window.localStorage.getItem(`article_rated_${this.attrs.article.id()}`);

      if(rated !== null) {
        this.cachedFeedback = true;
        this.chosenFeedbackPoints = Number(rated);
      }
    }

    // Feedback text
    this.feedbackText = Stream("");
  }

  view() {
    const defaultData = [{
      type: 'negative',
      points: 0,
      emoji: app.forum.attribute('support_knowledge_base_rating_emoji_bad')
    }, {
      type: 'neutral',
      points: 1,
      emoji: app.forum.attribute('support_knowledge_base_rating_emoji_neutral')
    }, {
      type: 'positive',
      points: 2,
      emoji: app.forum.attribute('support_knowledge_base_rating_emoji_positive')
    }];

    return (
      <div className={"Support-Article-Rating"}>
        <div className={"Support-Rating-Container"}>
          {this.feedbackProcess === 'rating' && this.isUpdating && (
            <LoadingIndicator size={"large"} />
          )}

          {/* Rate article */}
          {!this.isUpdating && this.feedbackProcess === 'rating' && (
            <div>
              <p>{app.forum.attribute('support_knowledge_base_rating_cta') !== "" ? app.forum.attribute('support_knowledge_base_rating_cta') : app.translator.trans('v17development-flarum-support.forum.support_ratings.helful_article')}</p>
    
              <div className={"Support-Rating-items"}>
                {defaultData.map(rating => {
                  // Hide neutral emoji when disabled
                  if(rating.type === 'neutral' && app.forum.attribute('support_knowledge_base_allow_neutral') == false) return null;

                  return (
                    <a 
                      href="#"
                      onclick={e => {
                        e.preventDefault();

                        // User is not logged in and guest votes aren't enabled
                        if(!app.session.user && app.forum.attribute('support_knowledge_base_can_vote') === false) {
                          app.modal.show(LogInModal);
                          return;
                        }
                        // This person cannot vote
                        else if(app.forum.attribute('support_knowledge_base_can_vote') === false) {
                          app.alerts.show(Alert, {
                            type: 'error'
                          }, app.translator.trans('v17development-flarum-support.forum.support_ratings.validation.cannot_rate_article'));
                          return;
                        }
        
                        this.addRating(rating.points);
                      }}
                      className={this.cachedFeedback ? this.chosenFeedbackPoints !== rating.points ? 'Rating-Not-Chosen' : 'Rating-Chosen' : null}
                      >
                      <img src={`https://twemoji.maxcdn.com/v/12.1.2/72x72/${rating.emoji}.png`} />
                    </a>
                    )
                })}
              </div>
            </div>
          )}

          {/* Make them giving feedback */}
          {this.feedbackProcess === 'feedback' && (
            <div>
              <p>{app.forum.attribute(`support_knowledge_base_rating_cta_${this.chosenFeedbackPoints > 0 ? 'positive' : 'negative'}`) !== "" ? app.forum.attribute(`support_knowledge_base_rating_cta_${this.chosenFeedbackPoints > 0 ? 'positive' : 'negative'}`) : app.translator.trans(`v17development-flarum-support.forum.support_ratings.${this.chosenFeedbackPoints > 0 ? 'feedback' : 'feedback_not_helpful'}`)}</p>

              <div className={"Support-Rating-items"}>
                <textarea className={"FormControl"} placeholder={app.translator.trans('v17development-flarum-support.forum.support_ratings.enter_feedback')} bidi={this.feedbackText} />

                <Button 
                  loading={this.isUpdating}
                  className={"Button Button--primary"}
                  onclick={() => this.addRating(this.chosenFeedbackPoints, this.feedbackText())}>
                  {app.translator.trans('v17development-flarum-support.forum.send')}
                </Button>
              </div>
            </div>
          )}

          {/* Finished form */}
          {this.feedbackProcess === 'finished' && (
            <div className={"Support-Rating-finished"}>
              <p>{app.forum.attribute('support_knowledge_base_rating_cta_feedback_submitted') !== "" ? app.forum.attribute('support_knowledge_base_rating_cta_feedback_submitted') : app.translator.trans('v17development-flarum-support.forum.support_ratings.finished')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  // Add rating
  addRating(points, feedback = null) {
    if(this.feedbackProcess === 'feedback' && !feedback) return;

    this.chosenFeedbackPoints = points;
  
    this.isUpdating = true;

    app.request({
      method: "POST",
      url: `${app.forum.attribute('apiUrl')}/support/ratings`,
      body: {
        data: {
          attributes: {
            rating: points,
            feedback
          },
          relationships: {
            discussion: {
              data: {
                id: this.attrs.article.id(),
                type: "discussion"
              }
            }
          }
        }
      }
    })
      .then(({ data }) => {
        // Next step
        if(this.feedbackProcess === 'rating') {
          this.feedbackProcess = 'feedback';

          if(data.attributes.feedback !== null) {
            this.feedbackText(data.attributes.feedback);
          }

          window.localStorage.setItem(`article_rated_${this.attrs.article.id()}`, points);
        }else if(this.feedbackProcess === 'feedback') {
          this.feedbackProcess = 'finished';
        }
      })
      .catch((data) => {
        if(data.status === 403) {
          return;
        }

        app.alerts.show(Alert, {
          type: 'error',
        }, "Something went wrong while voting");
      })
      .then(() => {
        this.isUpdating = false;
        m.redraw();
      })

  }
}