import Component from 'flarum/common/Component';
import humanTime from 'flarum/helpers/humanTime';
import Link from 'flarum/components/Link';
import SupportTicketDropdown from '../Dropdowns/SupportTicketDropdown';
import { TicketStatusTag } from '../Tickets/TicketStatusTag';
import TicketAssignee from '../Tickets/TicketAssignee';
import OverviewAgent from '../../pages/Tickets/OverviewAgent';
import Tooltip from '../Tooltip';

export default class TicketOverviewAgencyTicketRow extends Component {
  view() {
    const ticket = this.attrs.ticket;

    const isAgentOverview = app.forum.attribute('canViewTickets') && app.current.matches(OverviewAgent);

    return (
      <div className={"Flarum-Support-Tickets-Overview-data-ticket"}>
        <Link href={app.route('ticketsView', { id: ticket.id() })}>
          <span className={"Flarum-Support-Tickets-Overview-data--id"}>{ticket.id()}</span>
          <span className={"Flarum-Support-Tickets-Overview-data--info"}>
            <b>
              {isAgentOverview && ticket.remindedCount() !== null && (
                <Tooltip 
                  title={
                    app.translator.transChoice(`v17development-flarum-support.forum.tickets.reminders_sent`, ticket.remindedCount(), { 
                      count: ticket.remindedCount()
                    }).join(" ")
                  }
                  className={"far fa-clock Flarum-Support-ReminderClock"}
                  />
              )}

              {ticket.discussion().title()} 
            </b>

            {app.translator.trans(`v17development-flarum-support.forum.tickets.ticket_activity`, {
              username: <i>{ticket.discussion().user() ? ticket.discussion().user().displayName() : 'Deleted'}</i>,
              date: humanTime(ticket.discussion().lastPostedAt())
            })}
          </span>
          <span className={"Flarum-Support-Tickets-Overview-data--date"}>{humanTime(ticket.discussion().createdAt())}</span>
          {isAgentOverview && (
            <span className={"Flarum-Support-Tickets-Overview-data--agents"}>
              {ticket.assignees() && ticket.assignees().map(assignee => <TicketAssignee data={assignee} />)}
            </span>
          )}
          <span className={"Flarum-Support-Tickets-Overview-data--status"}>
            <TicketStatusTag ticket={ticket} />
          </span>
          {isAgentOverview && (
            <span className={"Flarum-Support-Tickets-Overview-data--settings"}></span>
          )}
        </Link>
          
        {isAgentOverview && (
          <div className={"Flarum-Support-Tickets-Overview-data-floating-button"}>
            <SupportTicketDropdown ticket={ticket} />
          </div>
        )}
      </div>
    );
  }
}