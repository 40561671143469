import Component from 'flarum/common/Component';
import ArticleReturnLink from './ArticleReturnLink';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import PageNotFound from '../PageNotFound';
import ArticleControls from './ArticleControls';
import ArticleRating from './ArticleRating';
import KnowledgeBaseArticleComposer from '../ArticleComposer/KnowledgeBaseArticleComposer';
import Alert from 'flarum/components/Alert';
import LeftArticleEditor from '../Modals/LeftArticleEditor';

export default class ArticleView extends Component {
  oninit(vnode) {
    super.oninit(vnode);
    
    this.loading = false;
    this.editArticle = false;

    this.beforeCloseEventListener = (event) => {
      if(!this.editArticle) return null;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
  }

  view() {
    // Still loading article
    if(this.attrs.loading) {
      return (
        <div className="Support-Loader">
          <span>Loading article</span> 
          <LoadingIndicator />
        </div>
      );
    }

    // No articles to show
    if(!this.attrs.article) {
      return (
        <PageNotFound />
      )
    }

    const article = this.attrs.article;

    const articlePost = article.firstPost() ? article.firstPost() : app.store.getById('posts', article.firstPostId());

    // Start editing and remove ?edit=true from URL
    if(!this.editArticle && m.route.param().edit && articlePost.canEdit()) {
      const url = `/knowledgebase/${article.slug()}`;
      window.history.replaceState(null, document.title,  url);
      m.route.set(url, true);

      // Start editing
      this.startEditing();
    }

    return (
      <div>
        {/* Article controls */}
        <ArticleControls article={article} onStartEditing={() => this.startEditing()} disabled={this.editArticle} />

        <div className="Category-Item Category-Head">
          <h4>
            <span><i className='fas fa-bookmark' /></span>
            {article.title()} 
            {article.isHidden() ? ` (${app.translator.trans('v17development-flarum-support.forum.article.hidden')})` : ''}
            <span className="Support-splitter">-</span>
            
            {/* Show return to button */}
            <ArticleReturnLink disabled={this.editArticle} />
          </h4>
        </div>
        
        <div className="Support-Article-Item">
          <div className="Post-body">
            {!this.editArticle && articlePost && m.trust(articlePost.contentHtml())}

            {!this.editArticle && !articlePost && <p>Error. First post not found</p>}

            {this.editArticle && (
              <KnowledgeBaseArticleComposer 
                composer={app.composer} 
                originalContent={articlePost.content()}
                placeholder={app.translator.trans('v17development-flarum-support.forum.composer.enter_message_here')}
                confirmExit={app.translator.trans('core.forum.composer_edit.discard_confirmation')}
                onsubmit={() => this.save()}
                disabled={this.loading}
                />
            )}
          </div>
        </div>

        {!this.editArticle && app.forum.attribute('support_knowledge_base_allow_ratings') == true && (
          <ArticleRating article={article} />
        )}
      </div>
    );
  }

  // Prevent 
  onremove() {
    if(this.editArticle) {
      this.removeBeforeUnloadEventListener();

      setTimeout(() => {
        app.modal.show(LeftArticleEditor, {
          onSave: () => this.save(true)
        });
      }, 100);
    }
  }

  // Remove event listener
  removeBeforeUnloadEventListener() {
    window.removeEventListener("beforeunload", this.beforeCloseEventListener);
  }

  // Save
  save(isLeaving = false) {
    const article = this.attrs.article.firstPost() ? this.attrs.article.firstPost() : app.store.getById('posts', this.attrs.article.firstPostId());

    this.loading = true;

    article.save({
      content: app.composer.fields.content()
    })
      .then(() => {
        this.editArticle = false;
        this.loading = false;

        if(isLeaving) {
          app.alerts.show(Alert, {
            type: 'success'
          }, app.translator.trans('v17development-flarum-support.forum.composer.article_saved'));
        }

        m.redraw();
      })
      .catch(() => {
        if(this.editArticle) {
          this.loading = false;
        }
      });
  }

  startEditing() {
    if(this.editArticle !== false) return;

    this.editArticle = true;

    // Add event listener
    window.addEventListener("beforeunload", this.beforeCloseEventListener);
  }
}