import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import ArticleView from '../../Components/Article/ArticleView';
import ArticleAuthors from '../../Components/Article/ArticleAuthors';
import getArticleAuthors from '../../utils/getArticleAuthors';
import ArticleWrittenAt from '../../Components/Article/ArticleWrittenAt';

export default class Article extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    this.loading = true;
    this.found = false;
    this.article = null;
    this.posts = [];
    this.authors = [];

    // Send history push
    app.history.push('knowledgeBaseArticle');

    this.loadArticle();
  }

  // Load article
  loadArticle() {
    const cachedArticle = app.store.getById('discussions', m.route.param('id').split('-')[0]);

    const preloadArticle = app.preloadedApiDocument() || (cachedArticle && cachedArticle.firstPost && cachedArticle.firstPost() ? cachedArticle : null);

    if (preloadArticle) {
      // We must wrap this in a setTimeout because if we are mounting this
      // component for the first time on page load, then any calls to m.redraw
      // will be ineffective and thus any configs (scroll code) will be run
      // before stuff is drawn to the page.
      setTimeout(this.show.bind(this, preloadArticle), 0);
    } else {
      app.store.find('discussions', m.route.param('id').split('-')[0])
      .then(this.show.bind(this))
      .catch(() => {
        this.loading = false;
        this.found = false;
        this.bodyClass = 'Support-Page-Not-Found';
        m.redraw();
      });
    }

    m.redraw();
  }

  // Show article
  show(article) {
    // Set article data
    this.article = article;

    // Set article posts
    this.posts = article.posts();

    // Set article authors
    // this.authors = getArticleAuthors(article.posts());

    // Update title
    app.setTitle(`${article.title()} - ${app.translator.trans('v17development-flarum-support.forum.knowledge_base')}`);

    if(m.route.param().id !== article.slug()) {
      const url =  `/knowledgebase/${article.slug()}`;

      m.route.set(url, true);
      window.history.replaceState(null, document.title, url);
    }

    this.loading = false;
    this.found = true;
    
    // Redraw
    m.redraw();
  }

  view() {
    return [
      <PeacefulHero />,
      <div className="Support-Block Block-CategoryFullWidth Support-Article">
        <div className="container">
          <div className="SupportCategoryContainer">
            <div className="Category-Item-Container Category-No-Border">
              <ArticleView
                article={this.article}
                loading={this.loading} />
            </div>
          </div>

          {/* Show article meta data */}
          {!this.loading && this.article && (
            <div className="Support-Article-MetaData">
              {/* <ArticleAuthors authors={this.authors} /> */}
              <ArticleWrittenAt article={this.article} />
            </div>
          )}
        </div>
      </div>
    ]
  }
}