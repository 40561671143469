import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import Button from 'flarum/components/Button';
import TicketOverviewAgencyTicketRow from '../../Components/TicketOverviewAgencyTicketRow/TicketOverviewAgencyTicketRow';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import LogInModal from 'flarum/components/LogInModal';

export default class OverviewUser extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    // Redirect if support tickets are disabled
    if(app.forum.attribute('supportTicketsEnabled') == false) {
      m.route.set(app.route("knowledgeBase"));
      return;
    };

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.title')} - ${app.translator.trans('v17development-flarum-support.forum.support_tickets')}`);

    if(!app.session.user) {
      setTimeout(() => app.modal.show(LogInModal), 100)
      
      return;
    }

    this.tickets = [];
    this.loading = true;
    this.hasMore = false;

    this.loadTickets();
  }

  loadTickets() {
    // Load data
    app.store.find(!this.hasMore ? `support/tickets?userView=true` : this.hasMore.replace(app.forum.attribute('apiUrl'), ""))
      .then(data => {
        data.forEach(ticket => this.tickets.push(ticket))

        this.hasMore = data.payload.links && data.payload.links.next ? data.payload.links.next : false
      })
      .catch(() => {})
      .then(() => {
        this.loading = false;
        
        m.redraw();
      });
  }

  view() {
    // Do not load anything if the user is not logged in
    if(!app.session.user) {
      return (
        <div className={"Flarum-Support-TicketOverview"}>
          <PeacefulHero />
          
          <div className="Support-Block Block-CategoryFullWidth">
            <div className="container">
              <div className="SupportCategoryContainer">
                <div className="Category-Item-Container Category-No-Border">
                  <div className="Category-Item">
                    <h4>
                      <span><i className='fas fa-ticket-alt' /></span>
                      {app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.title')}
                    </h4>
                  </div>

                  <div className={"Flarum-Support-No-tickets"}>
                    <p>{app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.description')}</p>

                    {Button.component({
                      className: 'Button Button--primary Flarum-Support-Create-ticket-button',
                      onclick: () => app.modal.show(LogInModal)
                    }, app.translator.trans('core.forum.log_in.title'))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div className={"Flarum-Support-TicketOverview"}>
        <PeacefulHero />
        <div className="Support-Block Block-CategoryFullWidth">
          <div className="container">
            <div className="SupportCategoryContainer">
              <div className="Category-Item-Container Category-No-Border">
                <div className="Category-Item">
                  <div className={"ButtonGroup"}>
                    {app.forum.attribute('canViewTickets') && (
                      Button.component({
                        className: 'Button',
                        onclick: () => m.route.set(app.route("ticketAdminOverview")),
                        icon: 'fas fa-headset'
                      }, app.translator.trans('v17development-flarum-support.forum.admin_overview'))
                    )}

                    {Button.component({
                      className: 'Button',
                      onclick: () => m.route.set(app.route("ticketComposer")),
                      icon: 'fas fa-edit',
                      disabled: !app.forum.attribute('canComposeSupportTickets')
                    }, app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.title'))}
                  </div>

                  <h4>
                    <span><i className='fas fa-ticket-alt' /></span>
                    {app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.title')}
                  </h4>
                </div>

                {/* No tickets */}
                {(!this.loading && (!this.tickets || (this.tickets && this.tickets.length === 0))) && (
                  <div className={"Flarum-Support-No-tickets"}>
                    <p>{app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.no_tickets')}</p>

                    {Button.component({
                      className: 'Button Button--primary Flarum-Support-Create-ticket-button',
                      onclick: () => m.route.set(app.route("ticketComposer")),
                      disabled: !app.forum.attribute('canComposeSupportTickets')
                    }, app.forum.attribute('canComposeSupportTickets') ? app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.title') : app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.not_allowed'))}
                  </div>
                )}

                {(this.tickets && this.tickets.length > 0) && (
                  <div className={"Flarum-Support-Tickets-overview"}>
                    <p>{app.translator.trans('v17development-flarum-support.forum.tickets.my_tickets.description')}</p>

                    <div className={"Flarum-Support-Tickets-Overview-data"}>
                      <div className={"Flarum-Support-Tickets-Overview-data-header"}>
                        <span className={"Flarum-Support-Tickets-Overview-data--id"}>#</span>
                        <span className={"Flarum-Support-Tickets-Overview-data--info"}>{app.translator.trans('v17development-flarum-support.forum.tickets.ticket')}</span>
                        <span className={"Flarum-Support-Tickets-Overview-data--date"}>{app.translator.trans('v17development-flarum-support.forum.tickets.opened_on')}</span>
                        <span className={"Flarum-Support-Tickets-Overview-data--status"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.status.title')}</span>
                      </div>

                      {this.tickets.map(ticket => {
                        return (
                          <TicketOverviewAgencyTicketRow ticket={ticket} />
                        );
                      })}

                      {this.hasMore !== false && (
                        <div className={"load-more-tickets"}>
                          {Button.component({
                            className: 'Button',
                            onclick: () => this.loadTickets(),
                            icon: 'far fa-arrow-alt-circle-down',
                            disabled: this.loading
                          }, app.translator.trans('v17development-flarum-support.forum.tickets.load_more.title'))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {this.loading && (
                  <LoadingIndicator />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}