import Component from 'flarum/common/Component';
import Button from 'flarum/components/Button';
import Link from 'flarum/components/Link';
import EditArticleListDropdown from '../Dropdowns/EditArticleListDropdown';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import EditArticleDropdown from '../Dropdowns/EditArticleDropdown';

export default class PeacefulArticleList extends Component {
  oninit(vnode) {
    super.oninit(vnode);

    // Load articles
    app.discussions.refreshParams({
      q: 'tag:' + this.attrs.currentTag.slug()
    });
  }

  view() {
    const state = this.attrs.state;
    
    let loading = null;

    if (state.discussions.length > 0 && state.isLoading()) {
      loading = LoadingIndicator.component();
    } else if (state.moreResults) {
      loading = Button.component({
        className: 'Button',
        icon: 'fas fa-chevron-down',
        onclick: state.loadMore.bind(state)
      }, app.translator.trans('core.forum.discussion_list.load_more_button'));
    }

    // No items
    if (state.isLoading() && state.discussions.length === 0) {
      return (
        <div className="Support-Loader">
          <span>{app.translator.trans('v17development-flarum-support.forum.overview.loading_articles')}</span> 
          <LoadingIndicator />
        </div>
      );
    }

    // No items
    if (state.empty()) {
      return (
        <div className="Support-Empty">
          {app.translator.trans('v17development-flarum-support.forum.overview.no_articles_found')}
        </div>
      );
    }

    return (
      <div>
        {state.discussions.map(article => {
          const post = article.mostRelevantPost() ? article.mostRelevantPost().contentPlain() : article.firstPost() && article.firstPost().contentPlain();

          return (
            <div className="Category-Item-Container Hide-Dropdown-Controls">
              <div className="Support-Settings-Holder">
                
                  {(article.canRename() || article.canHide()) && (
                    <EditArticleDropdown article={article} onListPage={true} />
                  )}
              </div>

              <Link href={app.route('knowledgeBaseArticle', { id: article.slug() })} className="Category-Item Is-Sub-Item">
                <h4>{ article.title() + (article.isHidden() ? ` (${app.translator.trans('v17development-flarum-support.forum.article.hidden')})` : '') }</h4>

                <p>{ post.length > 100 ? post.substr(0, 100) + '...' : post }</p>
              </Link>
            </div>
          );
        })}
        
        {loading && (
          <div className="SupportSearchList-loadMore">
            {loading}
          </div>
        )}
      </div>
    );
  }
}