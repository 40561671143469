import Component from 'flarum/common/Component';
import Button from 'flarum/components/Button';
import Dropdown from 'flarum/components/Dropdown';
import RenameTagModal from "../Modals/RenameTagModal";
import EditTagDescriptionModal from "../Modals/EditTagDescriptionModal";
import EditTagIconModal from "../Modals/EditTagIconModal";

export default class EditArticleListDropdown extends Component {
  oninit(vnode) {
    super.oninit(vnode);
    
    this.tag = this.attrs.currentTag;
    this.multiCategory = this.attrs.multiCategory || false;
    this.redirectAfterRename = this.attrs.redirectAfterRename || false;
  }

  view() {
    let buttons = [];

    if(!this.attrs.noCreateArticle && app.session.user.canEdit()) {
      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => this.newArticle(),
          icon: 'fas fa-plus'
        }, app.translator.trans('v17development-flarum-support.forum.overview.tools.new_article'))
      );
    }

    // Rename tag
    if(app.forum.attribute('adminUrl')) {
      if(!this.attrs.noCreateArticle) {
        buttons.push(<li className="Dropdown-separator"/>);
      }

      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(RenameTagModal, { 
            currentTag: this.tag,
            isMulti: this.multiCategory,
            redirect: this.redirectAfterRename 
          }),
          icon: 'fas fa-pencil-alt'
        }, app.translator.trans('v17development-flarum-support.forum.overview.tools.rename_category'))
      );

      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(EditTagDescriptionModal, { 
            currentTag: this.tag 
          }),
          icon: 'fas fa-paragraph'
        }, app.translator.trans('v17development-flarum-support.forum.overview.tools.edit_description'))
      );

      buttons.push(
        Button.component({
          className: 'Button',
          onclick: () => app.modal.show(EditTagIconModal, { 
            currentTag: this.tag 
          }),
          icon: 'fas fa-icons'
        }, app.translator.trans('v17development-flarum-support.forum.overview.tools.change_icon'))
      );
    }

    // Catch, the user has some kind of rights, but not enough
    if(buttons.length === 0) {
      return <div />;
    }

    const button = 
      Dropdown.component({
        icon: this.isSingleCategory ? 'fas fa-ellipsis-v' : 'fas fa-cog',
        buttonClassName: 'Button',
        menuClassName: "Dropdown-menu--right",
      }, buttons);

    // Button
    if(this.attrs.noContainer) {
      return button;
    }

    return (
      <div className="Support-Settings-Holder">
        {button}
      </div>
    );
  }

  /**
   * New article
   */
  newArticle() {
    let tags = [];

    // Add mail tag
    if(this.tag.isChild()) {
      tags.push(this.tag.parent().id());
    }

    tags.push(this.tag.id());

    m.route.set(app.route('knowledgeBaseComposer', { tags: tags.join(',') }));
  }
}