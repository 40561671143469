import Page from 'flarum/components/Page';
import Link from 'flarum/components/Link';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import KnowledgeBaseArticleComposer from '../../Components/ArticleComposer/KnowledgeBaseArticleComposer';
import Select from 'flarum/components/Select';
import Stream from 'flarum/utils/Stream';
import clsx from 'clsx';

export default class ComposeTicket extends Page {
  oninit(vnode) {
    super.oninit(vnode);
    
    // Redirect if support tickets are disabled
    if(app.forum.attribute('supportTicketsEnabled') == false) {
      m.route.set(app.route("knowledgeBase"));
      return;
    };

    // Redirect if the user cannot compose a aticket
    if(app.forum.attribute('canComposeSupportTickets') == false) {
      m.route.set(app.route("ticketsOverview"));
      return;
    };

    const availableTicketLanguages = app.store.all('supportTicketLanguages');

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.title')} - ${app.translator.trans('v17development-flarum-support.forum.support_tickets')}`);
    
    // Title
    this.title = Stream('');

    // Language
    this.language = Stream(availableTicketLanguages.length > 0 ? availableTicketLanguages[0].id() : '');

    // Notice for this language?
    this.notice = this.language() !== "" ? app.store.getById('supportTicketLanguages', this.language()).notice() : false;

    // Saving
    this.saving = false;

    // Ticket languages
    this.languages = {};

    availableTicketLanguages.forEach(language => {
      this.languages[language.id()] = language.fullName();

      // The forum display language is actually in the list
      if(app.translator.locale == language.code()) {
        this.language(language.id());
      }
    });
  }

  view() {
    if(app.forum.attribute('supportTicketsEnabled') == false || app.forum.attribute('canComposeSupportTickets') == false) return null;

    // No languages to choose from
    if(Object.keys(this.languages).length === 0) {
      return (
        <div className={"Post-body"} style={{ marginTop: '20px', textAlign: 'center'}}>
          <blockquote><div><p>Apologies, due to an issue it is currently not possible to create any support ticket. Please try again later or use <Link href={app.route('index')}>our public forums</Link> as a temporary solution.</p></div></blockquote>
        </div>
      );
    }

    return (
      <div className={"Flarum-Support-TicketComposerPage"}>
        <PeacefulHero />
        <div className="Support-Block Block-CategoryFullWidth Support-Ticket">
          <div className="container">
            <div className="SupportCategoryContainer">
              <div className="Category-Item-Container Category-No-Border">
                <div className="Category-Item">
                  <h4>
                    <span><i className='fas fa-ticket-alt' /></span>
                    {app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.title')}
                    <span className="Support-splitter">-</span>
                    <Link href={app.route('ticketsOverview')} className={"Support-Return-Button"}>
                      {app.translator.trans('v17development-flarum-support.forum.discard')}
                    </Link>
                  </h4>
                </div>

                <div className={"Support-TicketComposer-info"}>
                  <div className={"Support-TicketComposer-info-intro"}>
                    {app.translator.trans('v17development-flarum-support.forum.tickets.compose_ticket.private_support', {
                      a: <Link href={app.route('index')} />
                    })}
                  </div>

                  <div className={"Support-TicketComposer-info-row"}>
                  <div className={"field-name"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.title')}</div>
                    <div className={"field"}>
                      {Object.keys(this.languages).length > 1 && (
                        Select.component({
                          options: this.languages,
                          value: this.language(),
                          onchange: (val) => {
                            this.language(val);
                            
                            this.notice = app.store.getById('supportTicketLanguages', val).notice() || false;
                          }
                        })
                      )}

                      <div className={clsx("helpText", Object.keys(this.languages).length === 1 && "single-language")}>
                        {app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.description', {
                          language: this.languages[this.language()]
                        })}</div>

                      {/* Display notice */}
                      {this.notice && (
                        <div className={"Post-body"}>
                          <blockquote><div><p>{this.notice}</p></div></blockquote>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"Support-TicketComposer-info-row"}>
                    <div className={"field-name"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.subject.title')}</div>
                    <div className={"field"}>
                      <input
                        className="FormControl"
                        bidi={this.title}
                        disabled={this.saving || this.language() === ""}
                        placeholder={app.translator.trans('v17development-flarum-support.forum.tickets.fields.subject.placeholder')}
                        />
                      <div className={"helpText"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.subject.description')}</div>
                    </div>
                  </div>
                </div>

                {this.language() !== "" && (
                  <div className="Support-TicketComposer-context">
                    <div className={"Support-TicketComposer-context-info"}>
                      <b>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.information.title')}</b>
                      <div className={"helpText"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.information.placeholder')}</div>
                    </div>

                    <div className="Post-body">
                      <KnowledgeBaseArticleComposer
                        composer={app.composer} 
                        originalContent={""}
                        submitLabel={app.translator.trans('v17development-flarum-support.forum.create')}
                        placeholder={app.translator.trans('v17development-flarum-support.forum.composer.enter_message_here')}
                        onsubmit={() => this.create()}
                        disabled={this.saving}
                        />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  create() {
    // No language selected
    if(!this.language()) {
      alert("Please select a language");
      return;
    }

    if(!this.title() || !app.composer.fields.content()) return;

    // Create data object
    const body = {
      language: this.language(),
      title: this.title(),
      content: app.composer.fields.content()
    };

    // Start loading
    this.saving = true;

    app.request({
      method: "POST",
      url: `${app.forum.attribute('apiUrl')}/support/tickets`,
      body
    })
      .then(({ data }) => {
        m.route.set(app.route("ticketsView", {
          id: data.id
        }));
      })
      .catch(() => {
        m.redraw();
      })
      .then(() => {
        this.saving = false;
      })

  }
}