import Component from 'flarum/common/Component';

export default class Tooltip extends Component {
  view(vnode) {
    return (
      <span title={vnode.attrs.title} className={vnode.attrs.className}>
        {vnode.children}
      </span>
    )
  }

  oncreate(vnode) {
    super.oncreate(vnode);

    this.$().tooltip();
  }
}