import Component from 'flarum/common/Component';
import humanTime from 'flarum/helpers/humanTime';

export default class ArticleWrittenAt extends Component {
  view() {
    return (
      <div className="Article-Written-At">
        {this.attrs.article.posts() && this.attrs.article.posts()[0] && this.attrs.article.posts()[0].editedAt() && (
          <div>{app.translator.trans('v17development-flarum-support.forum.article.last_modified', {
            date: humanTime(this.attrs.article.posts()[0].editedAt())
          })}</div>
        )}
        {app.translator.trans('v17development-flarum-support.forum.article.created_at', {
          date: humanTime(this.attrs.article.createdAt())
        })}
      </div>
    );
  }
}