import Component from 'flarum/common/Component';

export default class KnowledgeBaseSearch extends Component {
  // Get search from route parameters
  oninit(vnode) {
    super.oninit(vnode);
    this.value = m.route.param().q ? m.route.param().q : '';
  }

  // Create search hero
  view() {
    return (
      <div className="SearchHero">
        {!this.attrs.hideIcon && <label className="search-icon-holder" htmlFor="support-search"><i className="fas fa-search"/></label>}
        <input 
          type={"input"}
          className={"FormControl"}
          placeholder={app.translator.trans('v17development-flarum-support.forum.search.search_knowledge_base')}
          value={this.value}
          onkeyup={e => {
            // Enter, redirect to search page
            if(e.keyCode === 13) {
              m.route.set(app.route('knowledgeBaseSearch', { q: e.target.value }));
              return;
            }

            this.value = e.target.value;
          }}
          />
      </div>
    );
  }
}