import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Select from 'flarum/components/Select';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';

export default class UpdateTicketLanguageModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.ticket = this.attrs.ticket;

    this.language = this.ticket.language() ? this.ticket.language().id() : null;

    // Ticket languages
    this.languagesList = {};

    app.store.all('supportTicketLanguages').forEach(language => {
      this.languagesList[language.id()] = language.fullName();
    });
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.placeholder');
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('name', <div className="Form-group">
      {Object.keys(this.languagesList).length >= 1 && (
        Select.component({
          options: this.languagesList,
          value: this.language,
          placeholder: app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.placeholder'),
          onchange: (val) => {
            this.language = val;            
          }
        })
      )}

      <span className={"helper-text"}>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.description')}</span>
    </div>, 50);

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading
      }, 'Update')}
    </div>, -10);

    return items;
  }

  submitData() {
    return {
      title: this.name(),
    };
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    this.ticket.save({
      relationships: {
        language: app.store.getById("supportTicketLanguages", this.language),
      }
    })
      .then(() => this.hide(), response => {
        this.loading = false;
        this.handleErrors(response);
      }
    );
  }
}