import Page from 'flarum/components/Page';
import Link from 'flarum/components/Link';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import KnowledgeBaseSearch from '../../Components/Search/KnowledgeBaseSearch';
import ArticleReturnLink from '../../Components/Article/ArticleReturnLink';
import KnowledgeBaseSearchList from '../../Components/Search/KnowledgeBaseSearchList';

export default class Search extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.search.title')} - ${app.translator.trans('v17development-flarum-support.forum.knowledge_base')}`);
    app.history.push('knowledgeBaseSearchPage', app.translator.trans('v17development-flarum-support.forum.title'));

    // this.searching = true;
    this.searchterm = m.route.param().q ? m.route.param().q : null;

    // Set body class
    this.bodyClass = 'Support-Page-search';

    if(this.searchterm) {
      app.discussions.refreshParams({
        q: 'in:knowledgebase ' + m.route.param().q
      });
    }
  }

  view() {
    return [
      <PeacefulHero />,
      <div className="Support-Block Block-CategoryFullWidth">
        <div className="container">
          <div className="SupportCategoryContainer">
            <div className="Category-Item-Container Category-No-Border">
              <div className="Category-Item Category-Head">
                <h4>
                  <span><i className={"fas fa-bookmark"} /></span> {app.translator.trans('v17development-flarum-support.forum.search.title')}

                  <span className="Support-splitter">-</span>
                  
                  <ArticleReturnLink />
                </h4>

                {!app.discussions.isLoading() && <p>{app.translator.trans('v17development-flarum-support.forum.search.searched_for', {
                  searchterm: <b>{this.searchterm}</b>,
                  results: app.discussions.discussions.length
                })}</p>}

                {!this.searchterm && (
                  <div>
                    <p style={{ marginBottom: '15px' }}>{app.translator.trans('v17development-flarum-support.forum.search.use_searchbox_below')}</p>
                    <KnowledgeBaseSearch hideIcon={true} />
                  </div>
                )}
              </div>

              <div className="Support-Clear" />
            </div>
            
            {this.searchterm && (
              <div>
                {app.discussions.isLoading() && (
                  <div className="Support-Loader">
                    <span>{app.translator.trans('v17development-flarum-support.forum.search.searching_for', {
                      searchterm: <i>{this.searchterm}</i>
                    })}</span> 
                    <LoadingIndicator />
                  </div>
                )}

                {/* Show search results */}
                <KnowledgeBaseSearchList state={app.discussions} searchterm={this.searchterm} />
              </div>
            )}
          </div>

          <div className="All-Items-Container">
            <Link
              className="All-Items"
              href={app.route("index")}
              >{app.translator.trans('v17development-flarum-support.forum.ask_a_question_on_forums')}</Link>
          </div>
        </div>
      </div>
    ];
  }
}