import Component from 'flarum/common/Component';
import KnowledgeBaseSearch from "../Search/KnowledgeBaseSearch";
import Link from 'flarum/components/Link';
import clsx from "clsx";

export default class PeacefulHero extends Component {
  view() {
    return (
      <div className={"SupportHero Hero-Peaceful Hero-With-Placer"}>
        <div className={clsx("HeroTop", this.attrs.hideContent && "HeroTopCompact")}>
          {!this.attrs.hideContent && (
            <div className="container">
              <h1>{app.translator.trans('v17development-flarum-support.forum.hero.help_question')}</h1>

              <KnowledgeBaseSearch />

              <div className="SupportTicket">
                {app.translator.trans('v17development-flarum-support.forum.hero.check_forum', {
                  a: <Link href={app.route("index")} />
                })}
              </div>
            </div>
          )}
        </div>
        <div className="HeroBottom">
          <div className="container">
            <div className="HeroRow">
              <div className="HeroItem">
                <i></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}