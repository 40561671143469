import NotificationsDropdown from 'flarum/components/NotificationsDropdown';
import UnansweredTicketsList from '../UnansweredTicketsList';

export default class UnansweredTicketsDropdown extends NotificationsDropdown {
  static initAttrs(props) {
    props.label = props.label || app.translator.trans('v17development-flarum-support.forum.tickets.new_tickets_dropdown.title');
    props.icon = props.icon || 'far fa-life-ring';

    super.initAttrs(props);
  }

  getMenu() {
    return (
      <div className={'Dropdown-menu ' + this.attrs.menuClassName} onclick={this.menuClick.bind(this)}>
        {this.showing ? UnansweredTicketsList.component({ state: this.attrs.state }) : ''}
      </div>
    );
  }

  // TODO: Remove this function on Flarum BETA 14 release
  onclick() {
    if (app.drawer.isOpen()) {
      this.goToRoute();
    } else {
      this.attrs.state.load();
    }
  }

  goToRoute() {
    m.route.set(app.route.set('tickets'));
  }

  getUnreadCount() {
    return app.forum.attribute('openTicketsCount');
  }

  getNewCount() {
    return app.session.user.attribute('unreadTicketsCount');
  }
}