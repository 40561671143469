import Page from 'flarum/components/Page';
import Button from 'flarum/components/Button';
import Switch from 'flarum/components/Switch';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import SupportTicketDropdown from '../../Components/Dropdowns/SupportTicketDropdown';
import { TicketStatusTag } from '../../Components/Tickets/TicketStatusTag';
import PostStream from "flarum/components/PostStream";
import PostStreamState from "flarum/states/PostStreamState";
import fullTime from 'flarum/helpers/fullTime';
import CloseTicketModal from '../../Components/Modals/CloseTicketModal';
import KnowledgeBaseArticleComposer from '../../Components/ArticleComposer/KnowledgeBaseArticleComposer';
import LogInModal from 'flarum/components/LogInModal';
import TicketAssignee from '../../Components/Tickets/TicketAssignee';
import UpdateTicketLanguageModal from '../../Components/Modals/UpdateTicketLanguageModal';

export default class Ticket extends Page {
  oninit(vnode) {
    super.oninit(vnode);
    
    // Redirect if support tickets are disabled
    if(app.forum.attribute('supportTicketsEnabled') == false) {
      m.route.set(app.route("knowledgeBase"));
      return;
    }

    // Show login modal
    if(!app.session.user) {
      setTimeout(() => app.modal.show(LogInModal), 100);
      
      return null;
    }

    this.ticket = null;
    this.loading = true;
    this.replying = false;
    this.replied = false;
    this.found = false;
    this.sticky = false;
    this.stickyHeight = 72;

    this.markAsSolved = false;
    
    this.id = m.route.param('id').split('-')[0];

    this.near = m.route.param("near") || 0;

    app.history.push('ticketView');

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.support_tickets')}`);

    this.loadTicket();
  }

  // Load ticket
  loadTicket() {
    const preloadTicket = app.preloadedApiDocument();

    if (preloadTicket) {
      // We must wrap this in a setTimeout because if we are mounting this
      // component for the first time on page load, then any calls to m.redraw
      // will be ineffective and thus any configs (scroll code) will be run
      // before stuff is drawn to the page.
      setTimeout(this.show.bind(this, preloadTicket), 0);
    } else {
      app.store.find('support/ticket', this.id)
        .then(this.show.bind(this))
        .catch(() => {
          this.loading = false;
          this.found = false;
          this.bodyClass = 'Support-Page-Not-Found';
          m.redraw();
        });
    }

    m.redraw();
  }

  // Show ticket
  show(ticket) {
    // Set ticket data
    this.ticket = ticket;

    // Update title
    app.setTitle(`${ticket.discussion().title()} - ${app.translator.trans('v17development-flarum-support.forum.support_tickets')}`);

    this.loading = false;
    this.found = true;

    let includedPosts = [];
    if (ticket.payload && ticket.payload.included) {
      const discussionId = ticket.discussion().id();

      includedPosts = ticket.payload.included
        .filter(
          (record) =>
            record.type === "posts" &&
            record.relationships &&
            record.relationships.discussion &&
            record.relationships.discussion.data.id === discussionId
        )
        .map((record) => app.store.getById("posts", record.id))
        .sort((a, b) => a.id() - b.id())
        .slice(0, 20);
    }

    this.stream = new PostStreamState(ticket.discussion(), includedPosts);

    this.stream.goToNumber(m.route.param('near') || 0, true).then(() => {
      app.current.set('discussion', ticket.discussion());
      app.current.set('stream', this.stream);
    });

    // Redraw
    m.redraw();
  }

  stickyTicketHeader(element) {
    window.addEventListener("scroll", e => {
      const objTop = jQuery(element).offset().top - 50;

      if (window.scrollY >= objTop && !this.sticky) {
        this.sticky = true;
        m.redraw();
      } 
      
      if (window.scrollY < objTop && this.sticky) {
        this.sticky = false;
        m.redraw();
      }
    })
  }

  view() {
    // Do not load anything if the user is not logged in
    if(!app.session.user) {
      return <PeacefulHero />;
    }

    return (
      <div className={"Flarum-Support-TicketPage"}>
        <PeacefulHero hideContent={app.forum.attribute('canViewTickets')} />
        <div className="Support-Block Block-CategoryFullWidth">
          <div className="container">
            <div className="SupportCategoryContainer">
              <div className="SupportTicketHeader" config={this.stickyTicketHeader.bind(this)}>
                <div className={this.sticky ? "Support-Ticket-Sticky-Item" : ""}>
                  <div className={this.sticky ? "container" : ''}>
                    <div className={"SupportTicketHeader-content"}>
                      <div className={"SupportTicketHeader-content-meta"}>
                        <h4>
                          <span><i className='fas fa-ticket-alt' /></span>
                          {app.translator.trans('v17development-flarum-support.forum.tickets.ticket')} #{this.id} - {!this.ticket ? (this.found ? 'Not found' : 'Loading...') : this.ticket.discussion().title()}
                        </h4>
                      </div>
                      <div className={"SupportTicketHeader-content-options"}>
                        {this.ticket && (
                          <TicketStatusTag ticket={this.ticket} tooltipPosition={app.screen() === "phone" ? "bottom" : "top"} />
                        )}

                        <div className={"TicketHeaderButtons"}>
                          {this.ticket && (
                            Button.component({
                              className: 'Button',
                              onclick: () => m.route.set(app.route(app.forum.attribute('canViewTickets') ? "ticketAdminOverview" : "ticketsOverview")),
                              icon: app.forum.attribute('canViewTickets') ? 'fas fa-headset' : 'fas fa-ticket'
                            }, app.translator.trans(app.forum.attribute('canViewTickets') ? "v17development-flarum-support.forum.tickets.ticket_overview" : 'v17development-flarum-support.forum.tickets.my_tickets.title'))
                          )}
                          
                          {this.ticket && app.forum.attribute('canViewTickets') && (
                            <SupportTicketDropdown ticket={this.ticket} ticketView />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                
              {this.sticky  && <div style={{ height: `${this.stickyHeight}px` }} />}

              {this.loading  && (
                <div style={{ paddingTop: '100px' }}>
                  <LoadingIndicator size={"large"} />
                </div>
              )}

              {!this.loading && !this.found && (
                <div className={"Flarum-Support-Ticket-Not-Found"}>
                  <p>{app.translator.trans("v17development-flarum-support.forum.tickets.ticket_does_not_exist")}</p>

                  {Button.component({
                    className: 'Button Button--primary Flarum-Support-return-button',
                    onclick: () => m.route.set(app.route(app.forum.attribute('canViewTickets') ? "ticketAdminOverview" : "ticketsOverview")),
                  }, app.translator.trans("v17development-flarum-support.forum.tickets.return_to_overview"))}
                </div>
              )}

              {this.ticket && (
                <div className={"Flarum-Support-Ticket-container"}>
                  <div className={"Flarum-Support-Ticket-container-posts"}>
                    {this.loading && (
                      <div style={{ paddingTop: '30px' }}>
                        <LoadingIndicator size={"large"} />
                      </div>
                    )}

                    {/* Notification */}
                    {!this.loading && this.ticket.ticketStatus() === 2 && (
                      <div class="Post-body TicketNotification TicketNotificationTop">
                        <blockquote>
                          {/* User view */}
                          {this.ticket.discussion().user() == app.session.user && (
                            <p><i className={"far fa-clock TicketNotificationIcon"} /> {app.translator.trans('v17development-flarum-support.forum.tickets.status.pending.description_user')}</p>
                          )}
                          {/* Agent view */}
                          {this.ticket.discussion().user() != app.session.user && (
                            <p>{app.translator.trans('v17development-flarum-support.forum.tickets.status.pending.description')}</p>
                          )}
                        </blockquote>
                      </div>
                    )}

                    {!this.loading &&
                      this.ticket &&
                      PostStream.component({
                        discussion: this.ticket.discussion(),
                        stream: this.stream,
                        onPositionChange: this.positionChanged.bind(this),
                      })}

                    {!this.loading && this.ticket.ticketStatus() === 3 && (
                      <div class="Post-body TicketNotification TicketNotificationBottom">
                        <blockquote>
                          <p><i className={"fas fa-lock TicketNotificationIcon"} /> {app.translator.trans(app.forum.attribute('canViewTickets') ? 'v17development-flarum-support.forum.tickets.status.closed.description' : 'v17development-flarum-support.forum.tickets.status.closed.is_closed')}</p>
                        </blockquote>
                      </div>
                    )}

                    {!this.replied && (
                      <KnowledgeBaseArticleComposer
                        composer={app.composer} 
                        originalContent={""}
                        submitLabel={this.ticket.ticketStatus() === 3 ? 
                          app.translator.trans('v17development-flarum-support.forum.reopen') : 
                          app.translator.trans("core.forum.discussion_controls.reply_button")}
                        placeholder={app.translator.trans('v17development-flarum-support.forum.composer.enter_message_here')}
                        onsubmit={() => this.reply()}
                        disabled={this.replying}
                        />
                    )}
                      
                    <div className={"Ticket-updateStatus"}>
                      {this.ticket.ticketStatus() === 3 && (
                        <span>{app.translator.trans("v17development-flarum-support.forum.tickets.status.closed.description")}</span>
                      )}

                      {this.ticket.ticketStatus() !== 3 && (
                        Switch.component({
                          state: this.markAsSolved == true,
                          disabled: this.replying,
                          onchange: val => {
                            this.markAsSolved = val;
                          },
                        }, app.translator.trans('v17development-flarum-support.forum.tickets.status.solved.mark_as_solved'))
                      )}
                      
                    </div>
                  </div>
                  <div className={"Flarum-Support-Ticket-container-settings"}>
                    <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                      <h4>{app.translator.trans('v17development-flarum-support.forum.tickets.opened_on')}</h4>
                      {fullTime(this.ticket.discussion().createdAt())}
                    </div>

                    {/* Ticket assignees */}
                    {app.forum.attribute('canViewTickets') && this.ticket.assignees() && this.ticket.assignees().length > 0 && (
                      <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                        <h4>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.assignees.title')}</h4>

                        {/* {!this.ticket.assignees() || this.ticket.assignees().indexOf(app.session.user) === -1 && (
                          <div>
                            <a href={"#"} className={"TicketMetaLink"}>Assign yourself</a> to this ticket.
                          </div>
                        )} */}
                        
                        <div className={"Flarum-Support-Ticket-container-settings-MetaItem-AvatarDisplay"}>
                          {this.ticket.assignees() && this.ticket.assignees().map(assignee => <TicketAssignee data={assignee} />)}
                        </div>
                      </div>
                    )}

                    {/* <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                      <h4>Department</h4>
                      
                      <i>No department set</i>
                    </div> */}

                    <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                      <h4>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.title')}</h4>
                      
                      {(
                        app.forum.attribute('canViewTickets') ? (
                          <a 
                            href={"#"}
                            className={"TicketMetaEditLink"}
                            onclick={(e) => {
                              e.preventDefault();

                              app.modal.show(UpdateTicketLanguageModal, {
                                ticket: this.ticket
                              });
                            }}
                          >
                            {this.ticket.language() ? this.ticket.language().fullName() : <span>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.empty')}</span>} <i className={"fas fa-edit"} />
                          </a>
                        )
                         : 
                         this.ticket.language() ? this.ticket.language().fullName() : <p>{app.translator.trans('v17development-flarum-support.forum.tickets.fields.language.empty')}</p>
                      )}
                    </div>

                    {/* <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                      <h4>Priority</h4>
                      
                      Default priority
                    </div> */}

                    {/* <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                      <h4>Notifications</h4>
                      
                      Receive everything
                    </div> */}

                    {app.forum.attribute('canViewTickets') && this.ticket.ticketStatus() !== 3 && (
                      <div className={"Flarum-Support-Ticket-container-settings-MetaItem"}>
                        <a href={"#"} onclick={e => {
                          e.preventDefault();
                          app.modal.show(CloseTicketModal, { ticket: this.ticket });
                        }}>
                          <i className={"fas fa-lock"} style={{ marginRight: '5px' }} /> {app.translator.trans('v17development-flarum-support.forum.tickets.tools.close_ticket')}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Keep position
  positionChanged(startNumber, endNumber) {
    const discussion = this.ticket.discussion();

    if (app.session.user && endNumber > (discussion.lastReadPostNumber() || 0)) {
      discussion.save({ lastReadPostNumber: endNumber });
      m.redraw();
    }
  }

  // Reply section
  reply() {
    const body = {
      data: {
        body: app.composer.fields.content(),
        markAsSolved: this.markAsSolved
      }
    };

    this.replying = true;

    app.request({
      method: "POST",
      url: `${app.forum.attribute('apiUrl')}/support/ticket/${this.ticket.id()}/reply`,
      body
    })
      .then((result) => {
        const post = app.store.pushPayload(result);

        // Reset content
        app.composer.fields.content("");
        
        // Show post
        this.stream.update().then(() => this.stream.goToNumber(post.number()));

        // Stop loading
        this.replying = false;
        this.saving = false;

        this.replied = true;

        setTimeout(() => {
          this.replied = false;
          m.redraw();
        }, 50)

        m.redraw();
      })
      .catch(() => {
        this.replying = false;
        this.saving = false;
        m.redraw();
      })
  }
}
