import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Switch from 'flarum/components/Switch';
import KnowledgeBaseAdminOverview from '../../Components/Overview/KnowledgeBaseAdminOverview';
import ArticleReturnLink from '../../Components/Article/ArticleReturnLink';
import LogInModal from 'flarum/components/LogInModal';

export default class AdminPage extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    // Set body class
    this.bodyClass = 'Support-Page-admin';
    
    // Show login modal
    if(!app.session.user) {
      setTimeout(() => app.modal.show(LogInModal), 100);
      
      return null;
    }
    // Redirect if support tickets are disabled
    else if(app.forum.attribute('support_knowledge_base_view_ratings') == false) {
      m.route.set(app.route("knowledgeBase"));
      return;
    };

    app.setTitle(app.translator.trans('v17development-flarum-support.forum.knowledge_base'));
    app.history.push('knowledgeBaseAdmin', app.translator.trans('v17development-flarum-support.forum.title'));

    app.discussions.refreshParams({
      q: 'in:knowledgebase'
    });

    this.showArticlesScoringLow = false;
  }

  view() {
    // Do not load anything if the user is not logged in
    if(!app.session.user) {
      return <PeacefulHero />;
    }

    return [
      <PeacefulHero />,
      <div className="Support-Block Block-CategoryFullWidth">
        <div className="container">
          <div className="SupportCategoryContainer">
            <div className="Category-Item-Container Category-No-Border">
              <div className="Category-Item Category-Head">
                <h4>
                  <span><i className={"fas fa-bookmark"} /></span> {app.translator.trans('v17development-flarum-support.forum.knowledge_base')}

                  <span className="Support-splitter">-</span>
                  
                  <ArticleReturnLink />
                </h4>
              </div>

              <div className="Support-Clear" />
            </div>

            <div className={"Article-switch-best-performing"}>
              {Switch.component({
                  state: this.showArticlesScoringLow,
                  onchange: (val) => {
                    this.showArticlesScoringLow = val;

                    app.discussions.refreshParams({
                      q: `in:knowledgebase ${val ? 'is:bestperforming' : ''}`
                    });
                  }
                },
                [
                  <b>{app.translator.trans('v17development-flarum-support.forum.support_ratings.switch_sort_score.label')}</b>,
                  <div className="helpText">{app.translator.trans('v17development-flarum-support.forum.support_ratings.switch_sort_score.description')}</div>
                ]
              )}
            </div>
            
            <KnowledgeBaseAdminOverview state={app.discussions} />

            {app.discussions.isLoading() && (
              <div className="Support-Loader">
                <LoadingIndicator />
              </div>
            )}
          </div>
        </div>
      </div>
    ];
  }
}