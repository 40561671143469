import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Switch from 'flarum/components/Switch';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';

export default class CloseTicketModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.ticket = this.attrs.ticket;
    this.markAsSolved = false;
    this.postMessage = false;
    this.postMessageBody = Stream('');
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return app.translator.trans('v17development-flarum-support.forum.tickets.tools.close_ticket');
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('name', <div className="Form-group">
      You are about to close the ticket named <i><b>{this.ticket.discussion().title()}</b></i>. Are you sure?
    </div>, 50);

    // Set as solved
    items.add('solved', <div className="Form-group">
      {Switch.component({
        state: this.markAsSolved == true,
        onchange: val => {
          this.markAsSolved = val;

          this.hasChanges = true;
        }
      }, [
        <b>Mark as solved</b>, 
        <div className="helpText">By selecting this, you mark this ticket as solved.</div>
      ])}
    </div>, 40);

    // Post 
    items.add('post-message', <div className="Form-group">
      {Switch.component({
        state: this.postMessage == true,
        onchange: val => {
          this.postMessage = val;

          this.hasChanges = true;
        }
      }, [
        <b>Post message</b>, 
        <div className="helpText">Post a message before closing the ticket.</div>
      ])}
    </div>, 40);

    if(this.postMessage) {
      items.add('post-message-body', <div className="Form-group">
        <label>Message:</label>
        <textarea className="FormControl" bidi={this.postMessageBody} />
      </div>, 30);
    }

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading
      }, app.translator.trans('v17development-flarum-support.forum.tickets.tools.close_ticket'))}
    </div>, -10);

    return items;
  }

  submitData() {
    return {
      title: this.name(),
    };
  }

  onsubmit(e) {
    e.preventDefault();

    if(this.ticket.discussion().isLocked()) {
      alert(`Ticket '${this.ticket.discussion().title()}' has already been closed.`);
      return;
    }

    this.loading = true;

    app.request({
      method: "POST",
      url: `${app.forum.attribute('apiUrl')}/support/ticket/${this.ticket.discussion().id()}/close`,
      body: {
        markAsSolved: this.markAsSolved,
        postMessage: this.postMessage,
        postMessageBody: this.postMessageBody()
      }
    })
      .then(result => {
        app.store.pushPayload(result);
        this.hide();
      })
      .catch(response => {
        this.loading = false;
        this.handleErrors(response);
        m.redraw();
      })

  }
}