import EventPost from 'flarum/components/EventPost';

export default class DiscussionConvertedToTicketPost extends EventPost {
  icon() {
    return 'fas fa-ticket-alt';
  }

  description() {
    return app.translator.trans('v17development-flarum-support.forum.tickets.convert_to_ticket.event', {
      ticket_title: <i>{this.attrs.post.discussion().title()}</i>
    });
  }
}
