import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import Stream from 'flarum/utils/Stream';
import CloseTicketModal from './CloseTicketModal';
import clsx from 'clsx';

export default class UpdateTicketStatusModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.ticket = this.attrs.ticket;

    this.ticketStatus = Stream(this.ticket.ticketStatus() || 0);

    this.onselect = this.attrs.onselect;

    this.redirect = this.attrs.redirect;
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return app.translator.trans('v17development-flarum-support.forum.tickets.tools.update_status');
  }

  content() {
    return (
      <div className={"UpdateTicketStatusModal"}>
        {this.renderButton(0, 'new', 'fas fa-life-ring')}

        {this.renderButton(1, 'open', 'fas fa-ticket-alt')}

        {this.renderButton(2, 'pending', 'fas fa-user-clock')}

        {this.renderButton(3, 'closed', 'fas fa-flag-checkered')}

        {this.renderButton(4, 'on_hold', 'far fa-pause-circle')}

        {this.renderButton(5, 'duplicate', 'far fa-copy')}
      </div>
    );
  }

  renderButton(status, statusLabel, icon) {
    return Button.component({
      onclick: () => {
        // Select
        if(this.onselect) {
          this.onselect(status);
          this.hide();
          return;
        }

        // Open new dialog
        if(statusLabel === 'closed' && !this.attrs.ticket.discussion().isLocked()) {
          this.hide();
          app.modal.show(CloseTicketModal, { ticket: this.attrs.ticket });

          return;
        }

        // Hide when the status is the same as the current state
        if(this.ticketStatus() == status) {
          this.hide();
          return;
        }

        this.ticketStatus(status);

        
        this.submit();
      },
      className: clsx('Button UpdateTicketStatusButton', this.ticketStatus() == status && 'UpdateTicketStatusButton--selected'),
      disabled: this.loading
    }, [
      <i className={icon} />,
      <strong>{app.translator.trans(`v17development-flarum-support.forum.tickets.status.${statusLabel}.title`)}</strong>,
      <span>{app.translator.trans(`v17development-flarum-support.forum.tickets.status.${statusLabel}.description`)}</span>
    ])
  }

  submit() {
    this.loading = true;
    
    this.ticket.save({
      ticketStatus: this.ticketStatus()
    })
      .then(() => this.hide())
      .catch(response => {
        this.loading = false;
        this.handleErrors(response);

        m.redraw();
      });
  }
}