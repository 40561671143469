import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import Button from 'flarum/components/Button';
import extractText from 'flarum/utils/extractText';

export default function addConvertToTicketControls() {
  let convertingIntoTicket = false;

  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (app.forum.attribute("supportTicketsEnabled") === true && discussion.canConvertIntoTicket()) {
      items.add('toTicket', Button.component({
        icon: 'fas fa-ticket-alt',
        onclick: this.convertToTicket.bind(discussion),
        loading: convertingIntoTicket
      }, app.translator.trans('v17development-flarum-support.forum.tickets.convert_to_ticket.button')));
    }
  });

  DiscussionControls.convertToTicket = function() {
    if(
      !confirm(
        extractText(
          app.translator.trans('v17development-flarum-support.forum.tickets.convert_to_ticket.confirm')
        )
      )
    ) return false;

    // Disable button
    convertingIntoTicket = true;

    app.request({
      method: "POST",
      url: `${app.forum.attribute('apiUrl')}/support/convert_into_ticket/${this.id()}`,
    })
      .then(result => {
        const ticket = app.store.pushPayload(result);

        // Redirect to ticket
        m.route.set(app.route('ticketsView', { id: ticket.id() }));
      })
      .catch(response => {
        convertingIntoTicket = false;
        m.redraw();
      })
  };
}
