import Component from 'flarum/common/Component';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Button from 'flarum/components/Button';
import Link from 'flarum/components/Link';
import EditArticleDropdown from '../Dropdowns/EditArticleDropdown';
import sortTags from '../../utils/sortTags';

export default class KnowledgeBaseSearchList extends Component {
  oninit(vnode) {
    super.oninit(vnode);

    // Get knowledge base categories
    this.knowledgeBaseTags = app.forum.attribute('support_knowledge_base_tags') || [];
  }

  view() {
    const state = this.attrs.state;
    
    let loading = null;

    if (state.discussions.length > 0 && state.isLoading()) {
      loading = LoadingIndicator.component();
    } else if (state.moreResults) {
      loading = Button.component({
        className: 'Button',
        icon: 'fas fa-chevron-down',
        onclick: state.loadMore.bind(state)
      }, app.translator.trans('core.forum.discussion_list.load_more_button'));
    }

    // No items
    if (state.empty()) {
      return (
        <div className="Support-Empty">
          <div className="Support-Clear" />
          {app.forum.attribute('support_knowledge_base_no_results') !== 'custom' && [
            <p>
              {app.translator.trans('v17development-flarum-support.forum.search.no_results', {
                searchterm: <i>{this.attrs.searchterm}</i>
              })}
            </p>,
            <p>
              {(app.forum.attribute("support_knowledge_base_no_results") === 'return_to_knowled_gebase' || app.forum.attribute("support_knowledge_base_no_results") === 'both') && (
                <Link href={app.route('knowledgeBase')}>{app.translator.trans('v17development-flarum-support.forum.return_to_home')}</Link>
              )}

              {app.forum.attribute("support_knowledge_base_no_results") === 'both' && ` ${app.translator.trans('v17development-flarum-support.forum.or')} `}

              {(app.forum.attribute("support_knowledge_base_no_results") === 'post_on_forum' || app.forum.attribute("support_knowledge_base_no_results") === 'both') && (
                <Link href={app.route('index')}>{app.translator.trans('v17development-flarum-support.forum.ask_your_question_on_forum')}</Link>
              )}
            </p>
          ]}

          {app.forum.attribute('support_knowledge_base_no_results') === 'custom' && m.trust(app.forum.attribute('support_knowledge_base_no_results_custom_message').replace('{searchterm}', this.attrs.searchterm))}
        </div>
      );
    }

    return (
      <div>
        {state.discussions.map(article => {
          const post = article.mostRelevantPost() ? article.mostRelevantPost().contentPlain() : article.firstPost() ? article.firstPost().contentPlain() : '';

          return (
            <div className="Category-Item-Container Hide-Dropdown-Controls Search-Item">
              <div className="Support-Settings-Holder">
                {(article.canRename() || article.canHide()) && <EditArticleDropdown article={article} onListPage={true} />}
              </div>

              <Link href={app.route('knowledgeBaseArticle', { id: article.slug() })} className="Category-Item Is-Sub-Item">
                <h4>{article.title() + (article.isHidden() ? ` (${app.translator.trans('v17development-flarum-support.forum.article.hidden')})` : '')}</h4>

                <p>{post.length > 100 ? post.substr(0, 100) + '...' : post}</p>
              </Link>

              <div className={"Search-Item-Breadcrumb"}>
                <span className={"Search-Item-Breadcrumb-Item"}>
                  <Link href={app.route('knowledgeBase')}>
                    {app.translator.trans('v17development-flarum-support.forum.knowledge_base')}
                  </Link>
                </span>

                {sortTags(article.tags()).map((tag, key) => {
                  if(this.knowledgeBaseTags.length === 1 && key === 0 || tag.position() === null) return null;

                  return (
                    <span className={"Search-Item-Breadcrumb-Item"}>
                      <Link href={this.knowledgeBaseTags.length > 1 && tag.parent() ? app.route('knowledgeBaseMultiCategory', { parent_category: tag.parent().slug(), category: tag.slug() }) : app.route('knowledgeBaseCategory', { category: tag.slug() })}>
                        {((this.knowledgeBaseTags.length === 1 && key >= 1) || this.knowledgeBaseTags.length > 1) && (
                          <i className={"fas fa-angle-right Search-Item-Breadcrumb-icon"} />
                        )}

                        {tag.name()}
                      </Link>
                    </span>
                  )
                })}
              </div>
            </div>
          );
        })}
        
        {loading && (
          <div className="SupportSearchList-loadMore">
            {loading}
          </div>
        )}
      </div>
    );
  }
}