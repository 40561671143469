import Page from 'flarum/components/Page';
import PeacefulHero from '../../Components/Hero/PeacefulHero';
import Switch from 'flarum/components/Switch';
import Button from 'flarum/components/Button';
import Stream from 'flarum/utils/Stream';
import KnowledgeBaseArticleComposer from '../../Components/ArticleComposer/KnowledgeBaseArticleComposer';
import TagDiscussionModal from 'flarum/tags/components/TagDiscussionModal';
import LeftArticleEditor from '../../Components/Modals/LeftArticleEditor';
import Alert from 'flarum/components/Alert';

export default class ComposeArticle extends Page {
  oninit(vnode) {
    super.oninit(vnode);

    app.setTitle(`${app.translator.trans('v17development-flarum-support.forum.composer.compose_article')} - ${app.translator.trans('v17development-flarum-support.forum.knowledge_base')}`);
    
    // Title
    this.title = Stream('');

    // Publish article after posting?
    this.publishArticle = true;
    this.articlePublished = false;

    // Tags
    this.tags = [];

    // Add tags if available
    if(m.route.param().tags) {
      const tagList = Array.isArray(m.route.param().tags) ? m.route.param().tags : m.route.param().tags.split(',');

      if(m.route.param().tags.length > 0) {
        tagList.forEach(tagId => {
          const foundTag = app.store.getById('tags', tagId);

          if(foundTag) {
            this.tags.push(foundTag);
          }
        });
      }
    }

    // Saving
    this.saving = false;

    this.beforeCloseEventListener = (event) => {
      if(app.composer.fields.content() === "") return null;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    // Add event listener
    window.addEventListener("beforeunload", this.beforeCloseEventListener);
  }

  // Prevent 
  onremove() {
    this.removeBeforeUnloadEventListener();

    if(!this.articlePublished && app.composer.fields.content() !== "") {
      setTimeout(() => {
        app.modal.show(LeftArticleEditor, {
          title: app.translator.trans('v17development-flarum-support.forum.composer.compose_article'),
          onSave: () => {
            // Save article, but hide it by default
            this.publishArticle = false;

            // If there is no title, make sure there is a title
            if(this.title() == "") {
              this.title("Unnamed article");
            }

            this.create(true);
          }
        });
      }, 100);
    }
  }

  // Remove event listener
  removeBeforeUnloadEventListener() {
    window.removeEventListener("beforeunload", this.beforeCloseEventListener);
  }
  
  // Open tag modal
  openTagsModal(isLeaving = false) {
    app.modal.show(TagDiscussionModal, {
      selectedTags: this.tags.slice(0),
      onsubmit: tags => {
        this.tags = tags;

        // Forgot to add tags, retry creation
        if(isLeaving) {
          this.create(true);
        }
      }
    });
  }

  view() {
    return (
      <div className={"Flarum-Support-ArticleComposerPage"}>
        <PeacefulHero />
        <div className="Support-Block Block-CategoryFullWidth Support-Article">
          <div className="container">
            <div className="SupportCategoryContainer">
              <div className="Category-Item-Container Category-No-Border">
                <div className={"Flarum-Support-ComposeArticle-settings"}>
                  <div className={"Flarum-Support-ArticleIcon"}><i className="fas fa-pencil-alt" /></div>
                  <div className={"Flarum-Support-ArticleTitle"}>
                    <input
                      className="FormControl"
                      bidi={this.title}
                      disabled={this.saving}
                      placeholder={app.translator.trans('v17development-flarum-support.forum.composer.new_article_title')}
                    />
                  </div>
                  <div className={"Flarum-Support-ArticleTags"}>
                    <Button 
                      className={"Button"} 
                      icon={"fas fa-tags"}
                      disabled={this.saving}
                      onclick={() => this.openTagsModal()}>
                        {app.translator.trans('v17development-flarum-support.forum.composer.manage_tags')}
                      </Button>
                  </div>
                  <div className={"Flarum-Support-PublishArticle"}>
                    {Switch.component({
                      state: this.publishArticle == true,
                      disabled: this.saving,
                      onchange: val => {
                        this.publishArticle = val;
                      },
                     }, [
                      <b>{app.translator.trans('v17development-flarum-support.forum.composer.publish_article')}</b>, 
                      <div className="helpText">{app.translator.trans('v17development-flarum-support.forum.composer.publish_article_hint')}</div>
                    ])}
                  </div>
                </div>
                
                <div className="Support-Article-Item">
                  <div className="Post-body">
                    <KnowledgeBaseArticleComposer 
                      composer={app.composer} 
                      originalContent={""}
                      submitLabel={app.translator.trans('v17development-flarum-support.forum.create')}
                      placeholder={app.translator.trans('v17development-flarum-support.forum.composer.enter_message_here')}
                      confirmExit={app.translator.trans('core.forum.composer_edit.discard_confirmation')}
                      onsubmit={() => this.create()}
                      disabled={this.saving}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  create(isLeaving = false) {
    const knowledgeBaseTags = app.forum.attribute('support_knowledge_base_tags') || [];

    // Force tags
    if(this.tags.length === 0) {
      this.openTagsModal(isLeaving);
      return;
    }

    // Find knowledge base tags
    const findKnowledgeBaseTags = this.tags.filter((tag) => { 
      return knowledgeBaseTags.indexOf(tag.id()) >= 0;
    });
    
    // No knowledge base tags selected
    if(findKnowledgeBaseTags.length === 0) {
      alert("You haven't selected any knowledge base tags. Please select at least one.");
      
      setTimeout(() => this.openTagsModal(isLeaving), 500);

      return;
    }

    // Create data object
    const data = {
      title: this.title(),
      content: app.composer.fields.content(),
      relationships: {
        tags: this.tags
      }
    };

    // Article hidden by default?
    if(!this.publishArticle) {
      data.hiddenAt = new Date();
      data.hiddenUser = app.session.user;
      data.isHidden = true;
    }

    // Start loading
    this.saving = true;

    app.store
      .createRecord('discussions')
      .save(data)
      .then((article) => {
        this.articlePublished = true;
        
        // Remove event listener
        this.removeBeforeUnloadEventListener();

        if(!isLeaving) {
          setTimeout(() => {
            // Redirect to the article
            m.route.set(app.route.knowledgeBaseArticle(article));
          }, 500);
        }else{
          app.alerts.show(Alert, {
            type: 'success',
            controls: [
              Button.component({
                className: "Button Button--link Alert-dismiss",
                onclick: () => m.route.set(app.route.knowledgeBaseArticle(article))
              }, "Open")
            ]
          }, app.translator.trans('v17development-flarum-support.forum.composer.article_saved'));
        }
      })
      .catch(() => {
        this.saving = false;
        m.redraw();
      })

  }
}