import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import Stream from 'flarum/utils/Stream';

export default class EditTagDescriptionModal extends Modal {
  oninit(vnode) {
    super.oninit(vnode);

    this.tag = this.attrs.currentTag;

    this.description = Stream(this.tag.description() || '');
  }

  className() {
    return 'Modal--small Support-Modal';
  }

  title() {
    return 'Edit category description';
  }

  content() {
    return (
      <div className="Modal-body">
        <div className="Form">
          {this.fields().toArray()}
        </div>
      </div>
    );
  }

  fields() {
    const items = new ItemList();

    items.add('description', <div className="Form-group">
      <label>Category description:</label>
      <textarea className="FormControl" bidi={this.description} />
    </div>, 30);

    items.add('submit', <div className="Form-group">
      {Button.component({
        type: 'submit',
        className: 'Button Button--primary SupportModal-save',
        loading: this.loading,
      }, 'Update')}
    </div>, -10);

    return items;
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    this.tag.save({
      description: this.description()
    }).then(
      () => {
        this.hide();
        m.redraw();
      },
      response => {
        this.loading = false;
        this.handleErrors(response);
      }
    );
  }
}