import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';

export default class SupportRating extends mixin(Model, {
    user: Model.hasOne('user'),
    userIp: Model.attribute('user_ip'),
    discussion: Model.hasOne('discussion'),
    points: Model.attribute('points'),
    feedback: Model.attribute('feedback'),
    createdAt: Model.attribute('createdAt'),
}) {}