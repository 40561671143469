import Component from 'flarum/common/Component';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Button from 'flarum/components/Button';
import Link from 'flarum/components/Link';
import avatar from 'flarum/helpers/avatar';
import username from 'flarum/helpers/username';
import icon from 'flarum/helpers/icon';
import humanTime from 'flarum/helpers/humanTime';

export default class UnansweredTicketsList extends Component {
  oninit(vnode) {
    super.oninit(vnode);
    this.state = this.attrs.state;
  }

  view() {
    const tickets = this.state.cache;

    return (
      <div className="NotificationList UnansweredTicketsList">
        <div className="NotificationList-header">
          {Button.component({
            className: 'Button Button--icon Button--link',
            onclick: this.state.markAsRead.bind(this.state),
            icon: 'fas fa-check',
            title: 'Mark as read'
          })}
          {Button.component({
            className: 'Button Button--icon Button--link',
            onclick: () => m.route.set(app.route("ticketAdminOverview")),
            icon: 'far fa-list-alt',
            title: 'Ticket list'
          })}

          <h4 className="App-titleControl App-titleControl--text">{app.translator.trans('v17development-flarum-support.forum.tickets.new_tickets_dropdown.title')}</h4>
        </div>
        <div className="NotificationList-content">
          <ul className="NotificationGroup-content">
            {tickets.length
              ? tickets.map(ticket => {
                const ticketDiscussion = ticket.discussion();

                return (
                  <li>
                    <Link href={app.route('ticketsView', { id: ticket.id() })} className="Notification">
                      {avatar(ticketDiscussion.user())}
                      {icon('fas fa-ticket-alt', {className: 'Notification-icon', style: 'margin-top: 3px;'})}
                      <span className="Notification-content">
                        {app.translator.trans(`v17development-flarum-support.forum.tickets.new_tickets_dropdown.${ticket.ticketStatus() === 0 ? 'ticket_new' : 'ticket_updated'}`, {username: username(ticketDiscussion.user()), ticket: <i>{ticketDiscussion.title()}</i>})}
                      </span>
                      {humanTime(ticketDiscussion.createdAt())}
                      <div className="Notification-excerpt">
                        {/* {ticketDiscussion.lastPost().contentPlain()} */}
                      </div>
                    </Link>
                  </li>
                );
              })
              : !this.state.loading
                ? <div className="NotificationList-empty">{app.translator.trans('v17development-flarum-support.forum.tickets.new_tickets_dropdown.no_tickets')}</div>
                : LoadingIndicator.component({className: 'LoadingIndicator--block'})}
          </ul>
        </div>
      </div>
    );
  }
}