import ComposerBody from 'flarum/components/ComposerBody';
import TextEditor from 'flarum/components/TextEditor';
import ComposerPostPreview from 'flarum/components/ComposerPostPreview';
import Button from 'flarum/components/Button';
import {extend} from 'flarum/extend';
import ArticleComposerPreview from './ArticleComposerPreview';

export default class KnowledgeBaseArticleComposer extends ComposerBody {
  oninit(vnode) {
    super.oninit(vnode);

    this.previewContent = false;

    if(this.attrs.ondiscard) {
      // extend(this.editor, 'controlItems', (items) => {
      //   items.add('discard-button', <Button className={"Button"} onclick={this.attrs.ondiscard}>{app.translator.trans('v17development-flarum-support.forum.discard')}</Button>);
      // }, 1);
    }
  }

  // Render
  view() {
    const hasContent = (this.composer.fields.content() && this.composer.fields.content() !== "");
    const loading = this.loading || this.attrs.disabled;

    return (
      <div className={`Flarum-Support-ArticleComposer ${loading ? 'Flarum-Support-ArticleComposer-Loading' : ''}`}>
        <div className={"Flarum-Support-ArticleComposer-tabs"}>
          <Button className={!this.previewContent && "AricleComposerButtonSelected"} onclick={() => this.previewContent = false}>{app.translator.trans('v17development-flarum-support.forum.composer.write')}</Button>
          <Button className={this.previewContent && "AricleComposerButtonSelected"} onclick={() => this.previewContent = true}>{app.translator.trans('v17development-flarum-support.forum.composer.view')}</Button>
        </div>
        
        <div className={`Composer Flarum-Support-ComposeArticle-body ${this.previewContent ? 'Flarum-Support-ComposeArticle-HideEditor' : ''}`}>
          {this.previewContent && (
            <div className={"Flarum-Support-ArticleComposer-preview"}>
              {!hasContent && (
                app.translator.trans('v17development-flarum-support.forum.composer.nothing_to_preview')
              )}
              
              <ArticleComposerPreview content={this.composer.fields.content()} />
            </div>
          )}

          {TextEditor.component({
            submitLabel: this.attrs.submitLabel || app.translator.trans('core.forum.composer_edit.submit_button'),
            placeholder: this.attrs.placeholder,
            disabled: loading,
            composer: this.composer,
            preview: this.jumpToPreview && this.jumpToPreview.bind(this),
            onchange: this.composer.fields.content,
            onsubmit: this.onsubmit.bind(this),
            value: this.composer.fields.content(),
          })}
        </div>
      </div>
    )
  }

  // Submit trigger
  onsubmit() {
    if(this.attrs.onsubmit) {
      this.attrs.onsubmit();
    }
  }
}