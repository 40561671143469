import IndexPage from 'flarum/components/IndexPage';
import DiscussionPage from 'flarum/components/DiscussionPage';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import { extend, override } from 'flarum/extend';

export default function() {
  // Redirect tag to KB category
  extend(IndexPage.prototype, 'oncreate', function() {
    const tag = this.currentTag();
    const tagRedirectEnabled = app.forum.attribute('support_knowledge_base_redirects_enabled') === 'both' || app.forum.attribute('support_knowledge_base_redirects_enabled') === 'tags_only';

    // Only trigger when it's a tag page and the redirects are enabled
    if(tag && tagRedirectEnabled) {
      const kbTags = app.forum.attribute('support_knowledge_base_tags');

      // Tag is inside list
      if(kbTags.indexOf(tag.id()) >= 0 || (tag.parent() && kbTags.indexOf(tag.parent().id()) >= 0)) {
        if(kbTags.length === 1) {
          if(tag.parent()) {
            m.route.set(
              app.route('knowledgeBaseCategory', {
                category: tag.slug()
              })
            );
          }else{
            // It's the home tag
            m.route.set(app.route('knowledgeBase'));
          }
        }else{
          // Tag has parent
          if(tag.parent()) {
            m.route.set(
              app.route('knowledgeBaseMultiCategory', {
                parent_category: tag.parent().slug(),
                category: tag.slug()
              })
            );
          }else{
            m.route.set(
              app.route('knowledgeBaseCategory', {
                category: tag.slug()
              })
            );
          }
        }
      }
    }
  });

  // Redirect discussion to KB article
  override(DiscussionPage.prototype, 'show', function(original, discussion) {
    const discussionRedirectEnabled = app.forum.attribute('support_knowledge_base_redirects_enabled') === 'both' || app.forum.attribute('support_knowledge_base_redirects_enabled') === 'discussions_only';
    
    if(discussion.supportTicket()) {
      m.route.set(
        app.route('ticketsView', {
          id: discussion.supportTicket().id()
        })
      , null, true);
      return;
    }

    if(discussionRedirectEnabled && discussion && discussion.tags().length > 0) {
      const kbTags = app.forum.attribute('support_knowledge_base_tags');

      const foundTags = discussion.tags().filter(tag => {
        return kbTags.indexOf(tag.id()) >= 0 ||  (tag.parent() && kbTags.indexOf(tag.parent().id()) >= 0)
      });

      // Only redirect if the discussion has knowledge base tags
      if(foundTags.length > 0) {
        // Redirect to discussion
        const url = app.route('knowledgeBaseArticle', {
          id: discussion.slug()
        });

        // Setting the 3rd argument to true replaces the current state in the browser history, that way the browser back button works as expected.
        m.route.set(url, null, true);
      }else{
        return original(discussion);
      }
    }else{
      return original(discussion);
    }
  });
}